import React, { useState } from 'react';
import Select, { StylesConfig, SingleValue } from 'react-select';
import { FormattedMessage } from 'react-intl';
import Arrow from '../../asset/icons/sorting/arrow-up.svg';
import './SortOrderSelect.scss';

export const selectStyle: StylesConfig<OptionType, false> = {
  control: (provided) => ({
    ...provided,
    borderColor: '#dedede',
    fontWeight: '400',
    textAlign: 'center',
    width: '120px',
    color: '#77838f;',
    minHeight: '23px',
    height: '23px',
    boxShadow: 'none',
    fontSize: '14px',
    backgroundColor: '#f7f8f9',
    borderRadius: '8px',
    '&:hover': {
      borderColor: '#dedede',
      backgroundColor: '#f1f2f3',
    },
    zIndex: 7,
  }),
  menuList: (base) => ({
    ...base,
    height: 'fit-content',
    fontSize: '14px',
    padding: '0px 0 5px 0',
    margin: '0',
    '::-webkit-scrollbar': {
      width: '4px',
    },
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#06c78c',
      borderRadius: 4,
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: '23px',
    padding: '0 6px',
  }),
  input: (provided) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorsContainer: (provided) => ({
    display: 'none',
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    textAlign: 'center',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    // eslint-disable-next-line
    backgroundColor: state.isSelected ? '#06C78C' : state.isFocused ? '#f0f0f0' : 'white', // NOSONAR
    color: state.isSelected ? 'white' : '#77838f',
    '&:hover': {
      backgroundColor: state.isSelected ? '#06C78C' : '#f0f0f0',
      color: state.isSelected ? 'white' : 'black',
    },
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 6,
    marginTop: '-10px',
    paddingTop: '15px',
    width: '98%',
    marginLeft: '1px',
    color: '#77838f;',
    borderRadius: '8px',
    overflow: 'hidden',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#77838f',
  }),
};

interface OptionType {
  label: React.ReactElement;
  value: string;
}

type SelectSortingFilterProps = {
  onSortChange: (sortField: string) => void;
  onOrderClick: () => void;
  options: OptionType[];
};

const SortOrderSelect = ({
  onSortChange,
  onOrderClick,
  options,
}:SelectSortingFilterProps) => {
  const [selectedValue, setSelectedValue] = useState<OptionType>(options[0]);
  const [isArrowUp, setIsArrowUp] = useState(true);

  const handleChangeSelect = (newValue: SingleValue<OptionType>) => {
    if (newValue) {
      setSelectedValue(newValue);
      onSortChange(newValue.value);
    }
  };

  const handleOrderClick = () => {
    setIsArrowUp((prevIsArrowUp) => !prevIsArrowUp);
    onOrderClick();
  };

  return (
    <div className="sorting">
      <label htmlFor="select-sorting">
        <p>
          <FormattedMessage id="sort.title" />
        </p>
        <Select
          id="select-sorting"
          value={selectedValue}
          options={options}
          styles={selectStyle}
          onChange={handleChangeSelect}
        />
      </label>
      <button className={`sorting__order ${!isArrowUp ? 'sorting__order--desc' : ''}`} onClick={handleOrderClick} type="button" data-testid="sort-order-button">
        <img src={Arrow} alt="Arrow" />
      </button>
    </div>
  );
};

export default SortOrderSelect;
