import { Auth, Amplify } from 'aws-amplify';

import { CognitoHostedUIIdentityProvider, CognitoUser } from '@aws-amplify/auth';
import { AwsConfigAuth } from '../../config/cognito/cognitoConfig';
import { ApiResponse } from '../../redux/slice/authSlice';
import userService from '../user/userService';
import { RegistrationFormValues } from '../../container/registration/Registration';

export interface CurrentUser {
  username: string
  userId: string
  email: string
  accessToken: string
  isCompletedRegistration: boolean,
}

Amplify.configure({ Auth: AwsConfigAuth });

function setCurrentUser(
  username: string,
  userId: string,
  email: string,
  accessToken: string,
  isCompletedRegistration: boolean,
) {
  const user: CurrentUser = {
    username,
    userId,
    email,
    accessToken,
    isCompletedRegistration,
  };

  localStorage.setItem('currentUser', JSON.stringify(user));
  return user;
}

export async function logout() {
  await Auth.signOut({ global: true });
  localStorage.clear();
  window.location.reload();
}

export async function register(registrationForm: RegistrationFormValues): Promise<ApiResponse> {
  await Auth.signUp(registrationForm.username, registrationForm.password, registrationForm.email);

  return { success: true, message: 'Successfully Registered' } as ApiResponse;
}

export async function login(username: string, password: string): Promise<ApiResponse> {
  const result = await Auth.signIn(username, password);
  const accessToken = result.signInUserSession.accessToken.jwtToken;
  const loginData = await userService.getUserLoginInfoWithCustomToken(accessToken);
  const user = setCurrentUser(
    result.username,
    result.attributes.sub,
    result.attributes.email,
    accessToken,
    loginData.registered,
  );
  return { success: true, message: '', currentUser: user } as ApiResponse;
}

export async function confirmRegistration(
  registrationForm: RegistrationFormValues,
): Promise<ApiResponse> {
  await Auth.confirmSignUp(registrationForm.username, registrationForm.confirmationCode);

  return { success: true, message: 'Successfully confirmed registration' };
}
const getCurrentUser = (): CurrentUser => {
  const userStr = localStorage.getItem('currentUser');
  let user = null;
  if (userStr !== null) {
    user = JSON.parse(userStr);
  }

  return user;
};

function setCurrentUserCompletedRegistration() {
  const currentUser = { ...getCurrentUser(), isCompletedRegistration: true };
  localStorage.setItem('currentUser', JSON.stringify(currentUser));
}

export async function resendConfirmationCode(username: string): Promise<void> {
  await Auth.resendSignUp(username);
}

export async function refreshCurrentUserFromAuthSessionAndGet(): Promise<CurrentUser> {
  const authSession: CognitoUser = await Auth.currentAuthenticatedUser();
  const username = authSession.getUsername();
  const userId = authSession.getSignInUserSession()?.getIdToken().payload.sub;
  const accessToken = authSession.getSignInUserSession()?.getAccessToken().getJwtToken();
  const email = authSession.getSignInUserSession()?.getIdToken().payload.email;
  const { registered } = await userService.getUserLoginInfoWithCustomToken(accessToken!);

  return setCurrentUser(username, userId, email, accessToken!, registered);
}

export async function refreshToken() {
  if (!getCurrentUser()) return;

  try {
    const currentUser = getCurrentUser();
    const currentSession = await Auth.currentSession();
    const accessToken = currentSession.getAccessToken().getJwtToken();
    localStorage.setItem(
      'currentUser',
      JSON.stringify({
        ...currentUser,
        accessToken,
      }),
    );
  } catch (error: any) {
    console.error(`Unable to refresh token. Error details:${error.message}`);
    throw error;
  }
}

export const getUserToken = () => getCurrentUser().accessToken;

export async function changePassword(
  oldPassword: string,
  newPassword: string,
): Promise<ApiResponse> {
  const currentUser = await Auth.currentAuthenticatedUser();
  await Auth.changePassword(currentUser, oldPassword, newPassword);

  return { success: true, message: 'Your password has been updated' };
}

export async function forgotPassword(username: string): Promise<ApiResponse> {
  await Auth.forgotPassword(username);
  return {
    success: true,
    message: 'Please check your email, we have already sent you a verification code',
  };
}

export async function forgotPasswordSubmit(
  username: string,
  verificationCode: string,
  newPassword: string,
): Promise<ApiResponse> {
  await Auth.forgotPasswordSubmit(username, verificationCode, newPassword);
  return {
    success: true,
    message: 'Your password has been updated',
  };
}

export function loginWithFacebook() {
  Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Facebook });
}

export function loginWithGoogle() {
  Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
}

const authService = {
  login,
  confirmRegistration,
  logout,
  register,
  getCurrentUser,
  refreshCurrentUserFromAuthSessionAndGet,
  getUserToken,
  refreshToken,
  changePassword,
  forgotPassword,
  forgotPasswordSubmit,
  loginWithFacebook,
  loginWithGoogle,
  resendConfirmationCode,
  setCurrentUserCompletedRegistration,
};

export default authService;
