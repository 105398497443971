import { Link } from 'react-router-dom';
import { APP_PATH } from '../../common/constant/pathConstant';
import './Title.scss';

// Meal Mate title in header / navbar component
const Title = () => (
  <div data-testid="title-nav">
    <Link to={`${APP_PATH.DASHBOARD}`} className="navigation_title title-nav">
      <div className="title-nav__wrapper">
        <span className="title-nav__green">Meal</span>
        <span>Mate</span>
      </div>
    </Link>
  </div>
);

export default Title;
