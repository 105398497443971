import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setMessage } from './messageSlice';
import authService, { CurrentUser } from '../../service/auth/authService';

import { convertToErrorMessage } from '../../service/message/messageConverter';
import { RegistrationFormValues } from '../../container/registration/Registration';

interface LoginData {
  username: string;
  password: string;
}

export interface ApiResponse {
  success: boolean;
  message: string;
  currentUser?: CurrentUser;
}

export const login = createAsyncThunk<ApiResponse, LoginData, { rejectValue: string }>(
  'auth/login',
  async ({ username, password }, thunkAPI) => {
    try {
      return authService.login(username, password);
    } catch (error: any) {
      thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const logout = createAsyncThunk('auth/logout', async () => {
  await authService.logout();
  window.location.reload();
});

export const register = createAsyncThunk<
  ApiResponse,
  RegistrationFormValues,
  { rejectValue: string }
>('auth/register', async (registrationForm, thunkAPI) => {
  try {
    return authService.register(registrationForm);
  } catch (error: any) {
    thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const confirmUser = createAsyncThunk<
  ApiResponse,
  RegistrationFormValues,
  { rejectValue: string }
>('auth/confirm', async (registrationForm, thunkAPI) => {
  try {
    const res = await authService.confirmRegistration(registrationForm);
    await thunkAPI.dispatch(login(registrationForm));

    return res;
  } catch (error: any) {
    thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const updateStateFromAuthSession = createAsyncThunk<CurrentUser>(
  'auth/sessionUpdate',
  async (_, thunkAPI) => {
    try {
      return await authService.refreshCurrentUserFromAuthSessionAndGet();
    } catch (error: any) {
      thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
      throw thunkAPI.rejectWithValue(error.message);
    }
  },
);

// @ts-ignore
const user: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));

export const initialState = {
  login: user
    ? {
      isLoggedIn: true,
      currentUser: user,
    }
    : {
      isLoggedIn: false,
      currentUser: null,
    },
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setRegistrationCompleted: (state) => {
      state.login.currentUser!.isCompletedRegistration = true;
    },
  },
  extraReducers: (reducerBuilder) => {
    reducerBuilder
      .addCase(login.fulfilled, (state, action) => {
        state.login.isLoggedIn = true;
        state.login.currentUser = action.payload.currentUser!;
      })
      .addCase(login.rejected, (state) => {
        state.login.isLoggedIn = false;
        state.login.currentUser = null;
      })
      .addCase(logout.fulfilled, (state) => {
        state.login.isLoggedIn = false;
        state.login.currentUser = null;
      })
      .addCase(register.fulfilled, (state) => {
        state.login.isLoggedIn = false;
      })
      .addCase(register.rejected, (state) => {
        state.login.isLoggedIn = false;
      })
      .addCase(confirmUser.fulfilled, (state) => {
        state.login.isLoggedIn = true;
      })
      .addCase(confirmUser.rejected, (state) => {
        state.login.isLoggedIn = false;
      })
      .addCase(updateStateFromAuthSession.fulfilled, (state, action) => {
        state.login.isLoggedIn = true;
        state.login.currentUser = action.payload;
      })
      .addCase(updateStateFromAuthSession.rejected, (state) => {
        state.login.isLoggedIn = false;
        state.login.currentUser = null;
      });
  },
});

const { reducer, actions } = authSlice;

export const { setRegistrationCompleted } = actions;

export default reducer;
