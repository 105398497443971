import React from 'react';
import { IntlProvider } from 'react-intl';
// @ts-ignore
import { ApmRoutes } from '@elastic/apm-rum-react';
import { BrowserRouter, Navigate, Route } from 'react-router-dom';
import { init as initApm } from '@elastic/apm-rum';
import Login from '../login/Login';
import RationList from '../ration/list/RationList';
import MealList from '../meal/list/MealList';
import ComponentLayout from '../../component/layout/ComponentLayout';
import { APP_PATH } from '../../common/constant/pathConstant';
import languageService from '../../service/language/languageService';
import { useAppSelector } from '../../hook/useAppSelector';
import AppLayout from '../../component/layout/app/AppLayout';
import Registration, { STEP_FOR_USER_DATA_FILL } from '../registration/Registration';
import Calculator from '../calculate/Calculator';
import Profile from '../profile/Profile';
import Setting from '../profile/setting/Setting';
import { MetricConfig } from '../../config/metric/metricConfig';
import { LOCAL_ENV_VALUE } from '../../config/config';
import OauthSuccessLogin from '../login/oauth/OauthSuccessLogin';
import ForgotPassword from '../login/forgotPassword/ForgotPassword';
import Meal from '../meal/Meal';
import IngredientList from '../ingredient/list/IngredientList';
import { RootState } from '../../redux';
import Ration from '../ration/Ration';
import Dashboard from '../dashboard/Dashboard';
import LoginEmailNotConfirmed from '../login/emailNotConfirmed/LoginEmailNotConfirmed';
import { CurrentUser } from '../../service/auth/authService';
import Privacy from '../legal/privacy/Privacy';
import Terms from '../legal/terms/Terms';
import PageNotFound from '../pageNotFound/PageNotFound';
import AppliedRationList from '../appliedRationList/AppliedRationList';

// metrics enable
/* eslint-disable no-unused-expressions */
process.env.REACT_APP_ENVIRONMENT !== LOCAL_ENV_VALUE && initApm(MetricConfig);

function App() {
  const locale = languageService.getCurrentLocale();
  languageService.setCurrentLocale(locale);
  const lang = languageService.getCurrentLanguage();
  const isLogged = useAppSelector((state: RootState) => state.auth.login.isLoggedIn);
  const currentUser: CurrentUser | null = useAppSelector(
    (state: RootState) => state.auth.login.currentUser,
  );

  const resolveRouteElement = () => {
    if (isLogged && currentUser?.isCompletedRegistration) {
      return <AppLayout />;
    }

    if (isLogged) {
      return <Navigate to={APP_PATH.LOGIN_USER_DATA_IS_NOT_FILLED} />;
    }

    return <Navigate to={APP_PATH.LOGIN} />;
  };

  return (
    <IntlProvider locale={locale} messages={lang}>
      <ComponentLayout>
        <BrowserRouter>
          <ApmRoutes>
            <Route
              path="/app"
              element={resolveRouteElement()}
            >
              <Route path={APP_PATH.PROFILE} element={<Profile />} />
              <Route
                path={APP_PATH.PROFILE_SETTING}
                element={<Setting onLanguageChange={languageService.updateLocal} />}
              />
              <Route path={APP_PATH.RATIONS} element={<RationList />} />
              <Route path={APP_PATH.APPLIED_RATIONS} element={<AppliedRationList />} />
              {/* @ts-ignore */}
              <Route path={`${APP_PATH.RATIONS}/:rationId`} element={<Ration />} />
              <Route path={APP_PATH.MEALS} element={<MealList />} />
              <Route path={`${APP_PATH.MEALS}/:mealId/:modal?`} element={<Meal />} />
              <Route path={APP_PATH.INGREDIENTS} element={<IngredientList />} />
              <Route path={`${APP_PATH.DASHBOARD}/:date?`} element={<Dashboard />} />
              <Route path={APP_PATH.CALCULATE} element={<Calculator />} />
              <Route path="*" element={<PageNotFound />} />

            </Route>
            <Route path={APP_PATH.REGISTRATION} element={<Registration />} />
            <Route path={APP_PATH.LOGIN} element={<Login />} />
            <Route path={APP_PATH.LOGIN_FORGOT_PASSWORD} element={<ForgotPassword />} />
            <Route path={APP_PATH.SUCCESS_LOGIN_REDIRECT} element={<OauthSuccessLogin />} />
            <Route
              path={APP_PATH.LOGIN_EMAIL_IS_NOT_CONFIRMED}
              element={<LoginEmailNotConfirmed />}
            />
            <Route
              path={APP_PATH.LOGIN_USER_DATA_IS_NOT_FILLED}
              element={<Registration startRegistrationStep={STEP_FOR_USER_DATA_FILL} />}
            />
            <Route
              path={APP_PATH.TERMS_AND_CONDITIONS}
              element={<Terms />}
            />
            <Route
              path={APP_PATH.PRIVACY_POLICY}
              element={<Privacy />}
            />
            <Route path="*" element={<Navigate to={APP_PATH.LOGIN} />} />
          </ApmRoutes>
        </BrowserRouter>
      </ComponentLayout>
    </IntlProvider>
  );
}

export default App;
