import { RationSearchSelectedParam } from '../../redux/slice/rationSlice';
import { SearchItem } from '../../component/filter/search/SearchFilter';
import mealService from '../meal/mealService';
import rationService from '../ration/rationService';
import ingredientService from '../ingredient/ingredientService';

function mapObjectToSearchParams(dto: any): string {
  const params = new URLSearchParams();

  Object.keys(dto).forEach((key) => {
    const value = dto[key];
    if (value !== undefined && value !== null) {
      if (Array.isArray(value) && value.length > 0) {
        params.append(key, value.join(','));
      } else if (typeof value === 'number') {
        params.append(key, value.toString());
      } else if (typeof value === 'string' && value !== '') {
        params.append(key, value);
      }
    }
  });

  return params.toString();
}

async function fetchSelectedMeals(ids: string[]): Promise<SearchItem[]> {
  const meals = await mealService.getMealsByIds(ids);
  return meals.map((meal) => ({
    id: meal.id,
    label: meal.name,
  }));
}

async function fetchSelectedIngredients(ids: string[]): Promise<SearchItem[]> {
  const ingredients = await ingredientService.getIngredientsByIds(ids);
  return ingredients.map((ingredient) => ({
    id: ingredient.id,
    label: ingredient.name,
  }));
}

async function fetchSelectedTags(ids: string[]): Promise<SearchItem[]> {
  const tags = await rationService.getRationTagsByIds(ids);
  return tags.map((tag) => ({
    id: tag.id,
    label: tag.name,
    color: tag.color,
  }));
}

/* eslint-disable no-await-in-loop */
async function mapSearchParamsToObject(searchParams: string): Promise<RationSearchSelectedParam> {
  const arrKeys = ['mealIds', 'ingredientIds', 'tagIds'];
  const params = new URLSearchParams(searchParams);
  const dto: any = {};
  const keys = Array.from(params.keys());

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const value = params.get(key)!;

    if (arrKeys.includes(key)) {
      if (key === 'mealIds') {
        dto.meals = await fetchSelectedMeals(value.split(','));
      } else if (key === 'tagIds') {
        dto.tags = await fetchSelectedTags(value.split(','));
      } else if (key === 'ingredientIds') {
        dto.ingredients = await fetchSelectedIngredients(value.split(','));
      }
    } else if (!Number.isNaN(Number(value))) {
      dto[key] = Number(value);
    } else {
      dto[key] = value;
    }
  }

  return dto;
}
// eslint-enable-next-line no-plusplus

const filterService = {
  mapObjectToSearchParams,
  mapSearchParamsToObject,
};

export default filterService;
