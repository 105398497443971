import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import userService, {
  DateWeightProgressLastUpdated,
  PutUserProfile,
  UserProfile,
  WeightProgressApiResponse,
  WeightUpdateApiRequest,
  WeightUpdateApiResponse,
} from '../../service/user/userService';
import { setMessage } from './messageSlice';
import { clearLoading, setLoading } from './loadingSlice';
import { convertToErrorMessage } from '../../service/message/messageConverter';

export interface FeedbackSentApiRequest {
  id: null;
  name: string;
  email: string;
  rating: number;
  text: string;
  refererUrl: string;
  userAccountIdentityProviderId: null;
  language: string;
}

export interface Goals {
  calorieGoal: number;
  fatGoal: number;
  proteinGoal: number;
  carboGoal: number;
}

export interface WeightProgressState {
  firstWeight: number;
  currentWeight: number;
  weightGoal: number;
  weightUnit: string;
}

interface AppManagementPreferences {
  management: {
    showUpdateWeighDialog: boolean;
  };
}

interface UserState {
  id: string;
  identityProviderId: string;
  email: string;
  username: string;
  name: string;
  gender: string;
  countryCode: string;
  languageCode: string;
  birthdayDate: string;
  height: number;
  heightUnit: string;
  weight: number;
  weightGoal: number;
  weightUnit: string;
  activityLevel: string;
  imageUrl: string;
  appliedGoals: Goals;
  selectedGoals: Goals;
  weightProgress: WeightProgressState;
  dateWeightUpdated: DateWeightProgressLastUpdated;
  app: AppManagementPreferences;
}

export const initialState: UserState = {
  id: '',
  identityProviderId: '',
  email: '',
  username: '',
  name: '',
  gender: '',
  countryCode: '',
  languageCode: '',
  birthdayDate: '',
  height: 0,
  heightUnit: '',
  weight: 0,
  weightGoal: 0,
  weightUnit: '',
  activityLevel: '',
  imageUrl: '',
  appliedGoals: {
    calorieGoal: 0,
    fatGoal: 0,
    proteinGoal: 0,
    carboGoal: 0,
  },
  selectedGoals: {
    calorieGoal: 0,
    fatGoal: 0,
    proteinGoal: 0,
    carboGoal: 0,
  },
  weightProgress: {
    firstWeight: 0,
    currentWeight: 0,
    weightGoal: 0,
    weightUnit: 'KILOGRAM',
  },
  dateWeightUpdated: {
    updatedAt: '',
  },
  app: {
    management: {
      showUpdateWeighDialog: false,
    },
  },
};

export const getUserProfile = createAsyncThunk<UserProfile, string, { rejectValue: string }>(
  'user/profile',
  async (userId, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoading());
      return await userService.getUserProfile(userId);
    } catch (error: any) {
      const { message } = error;
      thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
      return thunkAPI.rejectWithValue(message);
    } finally {
      thunkAPI.dispatch(clearLoading());
    }
  },
);

export const updateUserProfile = createAsyncThunk<
  PutUserProfile,
  { userId: string; updatedProfile: PutUserProfile },
  { rejectValue: string }
>('user/updateProfile', async ({ userId, updatedProfile }, thunkAPI) => {
  try {
    thunkAPI.dispatch(setLoading());
    return await userService.updateUserProfile(userId, updatedProfile);
  } catch (error: any) {
    const { message } = error;
    thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
    return thunkAPI.rejectWithValue(message);
  } finally {
    thunkAPI.dispatch(clearLoading());
  }
});

export const fetchNutritionGoals = createAsyncThunk<Goals, void, { rejectValue: string }>(
  'user/fetchNutritionGoals',
  async (_, thunkAPI) => {
    try {
      return await userService.getNutritionGoals();
    } catch (error: any) {
      const { message } = error;
      thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getWeightProgress = createAsyncThunk<
  WeightProgressApiResponse,
  void,
  { rejectValue: string }
>('weightProgress/getWeightProgress', async (_, thunkAPI) => {
  try {
    return await userService.getWeightProgress();
  } catch (error: any) {
    const { message } = error;
    thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateWeight = createAsyncThunk<
  { weight: number; weightGoal: number; },
  WeightUpdateApiRequest,
  { rejectValue: string }
>(
  'weightProgress/updateWeight',
  async (weightData, thunkAPI) => {
    try {
      const response: WeightUpdateApiResponse = await userService.updateWeightProgress(weightData);

      const weightUnitData = response.units.find((unit) => unit.unit === weightData.weightUnit);
      const updatedWeight = weightUnitData ? weightUnitData.value : weightData.weight;

      return {
        weight: updatedWeight,
        weightGoal: weightData.weightGoal,
      };
    } catch (error: any) {
      const { message } = error;
      thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateNutritionGoals = createAsyncThunk<Goals, Goals, { rejectValue: string }>(
  'user/updateNutritionGoals',
  async (goals, thunkAPI) => {
    try {
      await userService.updateNutritionGoals(goals);
      return goals;
    } catch (error: any) {
      const { message } = error;
      thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getWeighDateUpdated = createAsyncThunk<
  DateWeightProgressLastUpdated,
  void,
  { rejectValue: string }
>('weightDate/getWeightDateUpdated', async (_, thunkAPI) => {
  try {
    return await userService.getTimeWeightProgressLastUpdated();
  } catch (error: any) {
    const { message } = error;
    thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
    return thunkAPI.rejectWithValue(message);
  }
});

export const sendFeedback = createAsyncThunk<void, FeedbackSentApiRequest, { rejectValue: string }>(
  'user/sendFeedback',
  async (feedbackData, thunkAPI) => {
    try {
      return await userService.sendFeedback(feedbackData);
    } catch (error: any) {
      const { message } = error;
      thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const populateDefaultAppManagementPreferences = createAsyncThunk<
  AppManagementPreferences,
  void,
  { rejectValue: string }
>('user/appPreferenceSetUp', async (_, thunkAPI) => {
  try {
    const updateDate = await thunkAPI.dispatch(getWeighDateUpdated()).unwrap();
    let showUpdateWeighDialog = false;
    if (userService.isWeightUpdatedInExpectedDays(updateDate.updatedAt)) {
      showUpdateWeighDialog = true;
    }

    return {
      management: {
        showUpdateWeighDialog,
      },
    } as AppManagementPreferences;
  } catch (error: any) {
    const { message } = error;
    thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
    return thunkAPI.rejectWithValue(message);
  }
});

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setSelectedGoals: (state, action: PayloadAction<Goals>) => {
      state.selectedGoals = action.payload;
    },
    applySelectedGoals: (state) => {
      state.appliedGoals = { ...state.selectedGoals };
    },
    setShowUpdateWeightDialog: (state, action) => {
      state.app.management.showUpdateWeighDialog = action.payload;
    },
  },
  extraReducers: (reducerBuilder) => {
    reducerBuilder
      .addCase(getUserProfile.fulfilled, (state, action) => {
        Object.assign(state, action.payload);
      })
      .addCase(getUserProfile.rejected, () => {})
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        Object.assign(state, action.payload);
      })
      .addCase(updateUserProfile.rejected, () => {})
      .addCase(fetchNutritionGoals.fulfilled, (state, action) => {
        state.appliedGoals = action.payload;
        state.selectedGoals = action.payload;
      })
      .addCase(updateNutritionGoals.fulfilled, (state, action) => {
        state.appliedGoals = action.payload;
        state.selectedGoals = action.payload;
      })
      .addCase(getWeightProgress.fulfilled, (state, action) => {
        state.weightProgress = action.payload;
      })
      .addCase(getWeighDateUpdated.fulfilled, (state, action) => {
        state.dateWeightUpdated = action.payload;
      })
      .addCase(updateWeight.fulfilled, (state, action) => {
        state.weight = action.payload.weight;
        state.weightGoal = action.payload.weightGoal;
        state.weightProgress.currentWeight = action.payload.weight;
        state.weightProgress.weightGoal = action.payload.weightGoal;
      })
      .addCase(populateDefaultAppManagementPreferences.fulfilled, (state, action) => {
        state.app = action.payload;
      });
  },
});

export const {
  setSelectedGoals,
  applySelectedGoals,
  setShowUpdateWeightDialog,
} = userSlice.actions;
export const userReducer = userSlice.reducer;
