import { useEffect, useState } from 'react';
import './Meal.scss';
import { Link, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import ScaleBar from '../../component/scaleBar/ScaleBar';
import { ReactComponent as ArrowUp } from '../../asset/icons/dashboard/arrow-down.svg';
import Tags from '../../component/tag/TagList';
import ProteinIcon from '../../asset/icons/protein.svg';
import CaloriesIcon from '../../asset/icons/calories.svg';
import FatsIcon from '../../asset/icons/fat.svg';
import CarboIcon from '../../asset/icons/carbo.svg';
import ModalWindow from '../../component/layout/modalWindow/ModalWindow';
import { useAppDispatch } from '../../hook/useAppDispatch';
import { getMeal } from '../../redux/slice/mealSlice';
import { useAppSelector } from '../../hook/useAppSelector';
import { MealTypeEnum, Nutrition } from '../../common/constant/interface/interfaces';
import AddMealItemsToMyRation from '../../component/addMealItemsToMyRation/AddMealItemsToMyRation';
import { APP_PATH, BACKEND_INGREDIENTS_PARAMS } from '../../common/constant/pathConstant';

const nutritionToRepresentValue = new Map([
  [
    'protein',
    {
      displayName: <FormattedMessage id="protein" />,
      emoji: <img src={ProteinIcon} alt="proteins" />,
    },
  ],
  [
    'calorie',
    {
      displayName: <FormattedMessage id="calories" />,
      emoji: <img src={CaloriesIcon} alt="calories" />,
    },
  ],
  [
    'fat',
    { displayName: <FormattedMessage id="fats" />, emoji: <img src={FatsIcon} alt="fats" /> },
  ],
  [
    'carbo',
    {
      displayName: <FormattedMessage id="carbo" />,
      emoji: <img src={CarboIcon} alt="carbohydrates" />,
    },
  ],
]);

const Meal = () => {
  const dispatch = useAppDispatch();
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(true);
  const [isStepsExpanded, setIsStepsExpanded] = useState(false);
  const { mealId } = useParams<{ mealId: string }>();
  const meal = useAppSelector((state) => state.meal.meal);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (mealId && meal?.id !== mealId) {
      dispatch(getMeal(mealId));
    }
  }, [dispatch, mealId, meal]);

  const toggleDescriptionExpand = () => {
    setIsDescriptionExpanded(!isDescriptionExpanded);
  };

  const toggleStepsExpand = () => {
    setIsStepsExpanded(!isStepsExpanded);
  };
  return meal ? (
    <>
      {isModalOpen && (
        <ModalWindow handleCloseModal={() => setIsModalOpen(false)}>
          <AddMealItemsToMyRation item={meal} itemType={MealTypeEnum.Meal} />
        </ModalWindow>
      )}
      <section data-testid="meal-item-section" className="profile-window meals-item">
        <div className="meals-item__left">
          <div className="meals-item__header">
            <h1 className="meals-item__title">{meal.name}</h1>
            {meal.tags && <Tags tags={meal.tags} />}
          </div>
          <div className="meals-item__content meal-content">
            <Swiper
              modules={[Autoplay, Pagination, A11y]}
              spaceBetween={30}
              slidesPerView={1}
              preventInteractionOnTransition
              pagination={{ clickable: true }}
              autoplay={{
                delay: 5500,
                disableOnInteraction: true,
              }}
            >
              {meal.images.map((image) => (
                <SwiperSlide key={image.id}>
                  <div className="meal-content__picture">
                    <img src={image.url} alt={meal.name} />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="meal-content__info content-info">
              <div className="content-info__left ">
                <div className="content-info__time">
                  <div className="content-info__prep-time prep-time">
                    <h4 className="content-info__title">
                      <FormattedMessage id="meal.time.prep" />
                    </h4>
                    <p className="prep-time__value">
                      {meal.prepareTimeMinutes}
                      <FormattedMessage id="meal.time.minutes" />
                    </p>
                  </div>
                  <div className="content-info__cook-time cook-time">
                    <h4 className="content-info__title">
                      <FormattedMessage id="meal.time.cook" />
                    </h4>
                    <p className="cook-time__value">
                      {meal.cookTimeMinutes}
                      <FormattedMessage id="meal.time.minutes" />
                    </p>
                  </div>
                </div>
                <ScaleBar difficultyLevel={meal.difficulty} />
                <button
                  data-testid="openModal-button"
                  className="add-meal animated-gradient-btn add-meal--pc"
                  type="button"
                  onClick={() => setIsModalOpen(true)}
                >
                  <FormattedMessage id="meal.add.button" />
                </button>
              </div>
              <div className="content-info__right macronutrients">
                {meal.units[0].nutrition
                  && Array.from(nutritionToRepresentValue.keys()).map((key) => (
                    <div className="macronutrients__item" key={key}>
                      <h4 className="macronutrients__title">
                        {nutritionToRepresentValue.get(key)?.displayName}
                      </h4>
                      <p className="macronutrients__value">
                        {nutritionToRepresentValue.get(key)?.emoji}
                        {meal.units[0].nutrition[key as keyof Nutrition]}
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="meals-item__descr-wrapper">
            <div className="description">
              <div className="description__header">
                <h4 className="description__title">
                  <FormattedMessage id="meal.description.title" />
                </h4>
                <button
                  type="button"
                  onClick={toggleDescriptionExpand}
                  className={`expand-btn ${isDescriptionExpanded ? 'expanded' : ''}`}
                >
                  <ArrowUp />
                </button>
              </div>
              <div className={`content ${isDescriptionExpanded ? 'expanded' : ''}`}>
                {meal.description}
              </div>
            </div>
            <div className="description">
              <div className="description__header">
                <h4 className="description__title">
                  <FormattedMessage id="meal.steps.title" />
                </h4>
                <button
                  type="button"
                  data-testid="expandSteps-button"
                  onClick={toggleStepsExpand}
                  className={`expand-btn ${isStepsExpanded ? 'expanded' : ''}`}
                >
                  <ArrowUp />
                </button>
              </div>
              <div
                data-testid="steps"
                className={`content steps ${isStepsExpanded ? 'expanded' : ''}`}
              >
                <ul className="steps__list">
                  {meal.recipeSteps.length ? meal.recipeSteps.map((step) => (
                    <li key={step.id} className="steps__item">
                      {step.description}
                    </li>
                  )) : <p><FormattedMessage id="meal.no.steps" /></p>}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="meals-item__right meals-products">
          <h2 className="meals-products__title">
            <FormattedMessage id="meal.products.title" />
          </h2>
          <ul className="meals-products__list">
            {meal.ingredients.map((ingredients) => (
              <Link
                key={ingredients.ingredient.name}
                to={`${APP_PATH.APP_INGREDIENTS}?${BACKEND_INGREDIENTS_PARAMS.INGREDIENT_ID}=${ingredients.ingredient.id}`}
              >
                <li className="meals-products__item product">
                  <div className="product__left">
                    <div className="product__pic">
                      <img
                        src={ingredients.ingredient.images[0]?.url}
                        alt={ingredients.ingredient.name}
                      />
                    </div>
                    <div className="product__descr">
                      <h4 className="product__name">{ingredients.ingredient.name}</h4>
                      <p className="product__kcal">
                        {ingredients.nutrition.calorie}
                        kcal
                      </p>
                    </div>
                  </div>
                  <div className="product__right">
                    <div className="product__weight">
                      {ingredients.unit.value}
                      gr
                    </div>
                  </div>
                </li>
              </Link>
            ))}
          </ul>
          <button
            className="add-meal animated-gradient-btn add-meal--mobile"
            type="button"
            data-testid="add-meal-modal-button"
            onClick={() => setIsModalOpen(true)}
          >
            <FormattedMessage id="meal.add.button" />
          </button>
        </div>
      </section>
    </>
  ) : null;
};

export default Meal;
