import {
  initialAppliedSearchParamObject, initialSelectedSearchObject,
  MealFilterApiResponse, MealSearchSelectedParam,
} from '../../../redux/slice/mealSlice';
import {
  MealSearchRequestDto,
  MealSearchRequestDtoOptional,
} from '../../../common/constant/interface/interfaces';

const mapToMealSearchRequestDto = (
  filterApiResponse: MealFilterApiResponse,
): MealSearchRequestDto => ({
  ...initialAppliedSearchParamObject,
  prepareTimeMinutesMin: filterApiResponse.prepareTimeMinutesMin,
  prepareTimeMinutesMax: filterApiResponse.prepareTimeMinutesMax,
  cookTimeMinutesMin: filterApiResponse.cookTimeMinutesMin,
  cookTimeMinutesMax: filterApiResponse.cookTimeMinutesMax,
  difficultyMin: filterApiResponse.difficultyMin,
  difficultyMax: filterApiResponse.difficultyMax,
  calorieMin: filterApiResponse.calorieMin,
  calorieMax: filterApiResponse.calorieMax,
  proteinMin: filterApiResponse.proteinMin,
  proteinMax: filterApiResponse.proteinMax,
  carboMin: filterApiResponse.carboMin,
  carboMax: filterApiResponse.carboMax,
  fatMin: filterApiResponse.fatMin,
  fatMax: filterApiResponse.fatMax,
});

const mapSelectedSearchObjToMealSearchRequestDto = (
  selectedSearchParam: MealSearchSelectedParam,
  availableFilters: MealFilterApiResponse,
): MealSearchRequestDtoOptional => {
  const request: MealSearchRequestDtoOptional = {};

  const fieldMapping: { [key: string]: keyof MealFilterApiResponse } = {
    calorieMin: 'calorieMin',
    calorieMax: 'calorieMax',
    proteinMin: 'proteinMin',
    proteinMax: 'proteinMax',
    carboMin: 'carboMin',
    carboMax: 'carboMax',
    fatMin: 'fatMin',
    fatMax: 'fatMax',
    difficultyMin: 'difficultyMin',
    difficultyMax: 'difficultyMax',
    prepareTimeMinutesMax: 'prepareTimeMinutesMax',
    prepareTimeMinutesMin: 'prepareTimeMinutesMin',
    cookTimeMinutesMin: 'cookTimeMinutesMin',
    cookTimeMinutesMax: 'cookTimeMinutesMax',
  };

  Object.keys(fieldMapping).forEach((field) => {
    const filterField = fieldMapping[field];
    // @ts-ignore
    if (selectedSearchParam[field] !== availableFilters[filterField]) {
      // @ts-ignore
      request[field] = selectedSearchParam[field];
    }
  });

  request.tagIds = selectedSearchParam.tags?.map((el) => el.id);
  request.ingredientIds = selectedSearchParam.ingredients?.map((el) => el.id);
  request.foodTypeNames = selectedSearchParam.foodTypeNames;
  request.name = selectedSearchParam.name;

  return request;
};

const mapFilterApiResponseToMealSearchSelectedParam = (
  filterApiResponse: MealFilterApiResponse,
): MealSearchSelectedParam => ({
  ...initialSelectedSearchObject,
  prepareTimeMinutesMin: filterApiResponse.prepareTimeMinutesMin,
  prepareTimeMinutesMax: filterApiResponse.prepareTimeMinutesMax,
  cookTimeMinutesMin: filterApiResponse.cookTimeMinutesMin,
  cookTimeMinutesMax: filterApiResponse.cookTimeMinutesMax,
  difficultyMin: filterApiResponse.difficultyMin,
  difficultyMax: filterApiResponse.difficultyMax,
  calorieMin: filterApiResponse.calorieMin,
  calorieMax: filterApiResponse.calorieMax,
  proteinMin: filterApiResponse.proteinMin,
  proteinMax: filterApiResponse.proteinMax,
  carboMin: filterApiResponse.carboMin,
  carboMax: filterApiResponse.carboMax,
  fatMin: filterApiResponse.fatMin,
  fatMax: filterApiResponse.fatMax,
});

const mapToMealSearchSelectedParam = (
  appliedFilters: MealSearchRequestDto,
): MealSearchSelectedParam => ({
  ...initialSelectedSearchObject,
  prepareTimeMinutesMin: appliedFilters.prepareTimeMinutesMin,
  prepareTimeMinutesMax: appliedFilters.prepareTimeMinutesMax,
  cookTimeMinutesMin: appliedFilters.cookTimeMinutesMin,
  cookTimeMinutesMax: appliedFilters.cookTimeMinutesMax,
  difficultyMin: appliedFilters.difficultyMin,
  difficultyMax: appliedFilters.difficultyMax,
  calorieMin: appliedFilters.calorieMin,
  calorieMax: appliedFilters.calorieMax,
  proteinMin: appliedFilters.proteinMin,
  proteinMax: appliedFilters.proteinMax,
  carboMin: appliedFilters.carboMin,
  carboMax: appliedFilters.carboMax,
  fatMin: appliedFilters.fatMin,
  fatMax: appliedFilters.fatMax,
  ingredients: appliedFilters.ingredients,
  tags: appliedFilters.tags,
  foodTypeNames: appliedFilters.foodTypeNames,
});

const mealMapper = {
  mapToMealSearchRequestDto,
  mapToMealSearchSelectedParam,
  mapFilterApiResponseToMealSearchSelectedParam,
  mapSelectedSearchObjToMealSearchRequestDto,
};

export default mealMapper;
