import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch } from '../../hook/useAppDispatch';
import { useAppSelector } from '../../hook/useAppSelector';
import {
  getMyRationForDay, PostMyRationItem, postMyRationItemsOnDay, resetState,
} from '../../redux/slice/myRationSlice';
import languageService from '../../service/language/languageService';
import { setMessage } from '../../redux/slice/messageSlice';
import { convertToErrorMessage } from '../../service/message/messageConverter';
import CalendarDatePicker from '../../container/dashboard/component/calendar/CalendarDatePicker';
import LoadingSpinner from '../spinner/LoadingSpinner';
import MyRationsList from '../../container/dashboard/component/myRation/MyRationsList';
import './AddMealItemsToMyRation.scss';
import {
  Ingredient, IngredientUnit, Meal, MealTypeEnum, Nutrition,
} from '../../common/constant/interface/interfaces';
import AddIngredientItem from '../../container/dashboard/component/addMealsModal/tab/ingredient/AddIngredientItem';
import AddMealItem from '../../container/dashboard/component/addMealsModal/tab/meal/AddMealItem';
import { calculateNutrition } from '../../service/myRation/calculateNutrition';
import { DATE_PICKER_FORMAT } from '../../common/constant/dateFormat';

interface AddMealItemsToMyRationProps {
    item: Meal | Ingredient
    itemType: MealTypeEnum.Meal | MealTypeEnum.Ingredient
}

const AddMealItemsToMyRation = ({ item, itemType }: AddMealItemsToMyRationProps) => {
  const dispatch = useAppDispatch();
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedItem, setSelectedItem] = useState<Meal | Ingredient>(item);
  const [isLoading, setIsLoading] = useState(false);
  const formatSelectedDate = format(selectedDate, DATE_PICKER_FORMAT);
  const myRations = useAppSelector((store) => store.myRation.myRations);
  const findRationOnDay = myRations.find((item) => item.day === formatSelectedDate);
  const isMeal = (item: Meal | Ingredient): item is Meal => itemType === MealTypeEnum.Meal;

  useEffect(() => {
    if (!findRationOnDay) {
      dispatch(getMyRationForDay(formatSelectedDate));
    }
    return () => {
      dispatch(resetState());
    };
  }, []);

  const getMyRationOnDay = async () => {
    if (!findRationOnDay) {
      setIsLoading(true);
      await dispatch(getMyRationForDay(formatSelectedDate))
        .unwrap()
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const createItemData = (foodType: string): PostMyRationItem['content'][0] => {
    const baseItem = {
      day: formatSelectedDate,
      foodType,
      language: languageService.getCurrentLocale(),
      item: {
        name: selectedItem.name,
        language: languageService.getCurrentLocale(),
        nutrition: selectedItem.units[0].nutrition,
        unit: {
          id: selectedItem.units[0].unit.id,
          name: selectedItem.units[0].unit.name,
          value: selectedItem.units[0].unit.value,
        },
      },
    };

    if (itemType === MealTypeEnum.Meal) {
      return {
        ...baseItem,
        item: {
          ...baseItem.item,
          mealId: selectedItem.id,
          type: MealTypeEnum.Meal,
        },
      };
    }
    return {
      ...baseItem,
      item: {
        ...baseItem.item,
        ingredientId: selectedItem.id,
        type: MealTypeEnum.Ingredient,
      },
    };
  };

  const handleAddMealsItem = (collectSelectedItemData: PostMyRationItem) => {
    dispatch(postMyRationItemsOnDay({ day: formatSelectedDate, items: collectSelectedItemData }))
      .unwrap()
      .catch((error: any) => {
        dispatch(setMessage(convertToErrorMessage(error)));
      });
  };

  const addMealsItem = (foodType: string) => {
    const itemData = createItemData(foodType);

    handleAddMealsItem({
      language: languageService.getCurrentLocale(),
      content: [itemData],
    });
  };

  const updateSelectedItem = (params: {
    selectedUnitName: string;
    selectedValue: number;
  }) => {
    const { selectedUnitName, selectedValue } = params;
    const validValue = Number.isNaN(selectedValue) || selectedValue <= 0 ? 0.1 : selectedValue;

    const baseNutrition = selectedItem.units[0].nutrition;
    const baseUnitValue = selectedItem.units[0].unit.value;

    const nutrition: Nutrition = calculateNutrition(
      baseNutrition,
      selectedUnitName,
      baseUnitValue,
      validValue,
    );

    setSelectedItem((prev) => ({
      ...prev,
      units: [
        {
          ...prev.units[0],
          unit: {
            ...prev.units[0].unit,
            name: selectedUnitName as IngredientUnit['name'],
            value: validValue,
          },
          nutrition,
        },
      ],
    }));
  };

  const renderSelectedItem = () => {
    if (isMeal(selectedItem)) {
      return (
        <AddMealItem
          key={selectedItem.id}
          meal={selectedItem}
          selected={false}
          value={selectedItem.units[0].unit.value}
          unitName={selectedItem.units[0].unit.name}
          onSelect={() => {}}
          onValueChange={(params) => updateSelectedItem({
            selectedUnitName: params.selectedUnitName,
            selectedValue: params.selectedValue,
          })}
          onUnitChange={(params) => updateSelectedItem({
            selectedUnitName: params.selectedUnitName,
            selectedValue: params.selectedValue,
          })}
        />
      );
    }

    return (
      <AddIngredientItem
        key={selectedItem.id}
        ingredient={selectedItem as Ingredient}
        selected={false}
        value={selectedItem.units[0].unit.value}
        unitName={selectedItem.units[0].unit.name}
        onSelect={() => {}}
        onValueChange={(params) => updateSelectedItem({
          selectedUnitName: params.selectedUnitName,
          selectedValue: params.selectedValue,
        })}
        onUnitChange={(params) => updateSelectedItem({
          selectedUnitName: params.selectedUnitName,
          selectedValue: params.selectedValue,
        })}
      />
    );
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="loading" data-testid="loading-spinner">
          <LoadingSpinner />
        </div>
      );
    }

    return (
      <MyRationsList
        myRationOnDay={findRationOnDay}
        addMealToRation={addMealsItem}
        itemId={selectedItem.id}
        nutritionValue={selectedItem.units[0].unit.value}
        selectedUnitName={selectedItem.units[0].unit.name}
      />
    );
  };

  return (
    <>
      <div className='selected-item'>
        <h2 className="title-side">
          <FormattedMessage id="dashboard.title.selectedItem" />
        </h2>
        {renderSelectedItem()}
      </div>

      <CalendarDatePicker
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        getMyRationOnDay={getMyRationOnDay}
      />
      <h2 className="title-side">
        <FormattedMessage id="dashboard.title.myRation" />
      </h2>
      <div className="content-wrapper">
        {renderContent()}
      </div>
    </>
  );
};

export default AddMealItemsToMyRation;
