import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ReactComponent as Delete } from '../../../asset/icons/filter/delete.svg';
import './SearchFilter.scss';
import LoadingSpinner from '../../spinner/LoadingSpinner';

export type SearchItem = {
  id: string,
  label: string,
  color?: string;
};

type SearchFilterProps = {
  titleId: string,
  filterType: 'tags' | 'meals' | 'ingredients';
  itemsToSelect: SearchItem[],
  selectedItems: SearchItem[],
  loadItems: () => void;
  searchItems: (name: string) => void;
  onChange: (value: SearchItem[]) => void,
  hasMoreItemsToLoad: boolean,
};

const SearchFilter = ({
  titleId,
  filterType,
  itemsToSelect,
  selectedItems,
  loadItems,
  searchItems,
  onChange,
  hasMoreItemsToLoad,
}: SearchFilterProps) => {
  const [isOpened, setIsOpened] = useState<boolean>();
  const [searchValue, setSearchValue] = useState<string>('');
  const ref = useRef(null);
  const intl = useIntl();

  const translate = (id:string) => intl.formatMessage({ id });

  const handleClickOutside = (event: { target: any; }) => {
    // @ts-ignore
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpened(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);

    return () => window.removeEventListener('click', handleClickOutside);
  }, []);

  const handleSearchClick = () => {
    setIsOpened(!isOpened);
    if (itemsToSelect.length === 0) {
      loadItems();
    }
  };

  return (
    <div className="search-filter">
      <h3 className="search-filter__title">
        <FormattedMessage id={titleId} />
      </h3>
      <div className="search" ref={ref}>
        <input
          className="search__input"
          type="search"
          placeholder={translate('search.placeholder')}
          value={searchValue}
          onChange={(e) => {
            setIsOpened(true);
            const searchName = e.target.value;
            searchItems(searchName);
            setSearchValue(searchName);
          }}
          onClick={handleSearchClick}
        />
        {isOpened && (
          <div
            id="scrollableDiv"
            data-testid="search-dropdown"
          >
            <InfiniteScroll
              dataLength={itemsToSelect.length}
              hasMore={hasMoreItemsToLoad}
              next={loadItems}
              height="100%"
              loader={<LoadingSpinner />}
            >
              {itemsToSelect.map((result) => (
                <li key={result.id} role="option" aria-selected={false}>
                  <button
                    type="button"
                    className={`search__dropdown-item ${selectedItems.some((el) => el.id === result.id) ? 'selected' : ''}`}
                    onClick={() => {
                      if (selectedItems.some((el) => el.id === result.id)) {
                        onChange(selectedItems.filter((el) => el.id !== result.id));
                      } else {
                        onChange([...selectedItems, result]);
                      }
                    }}
                  >
                    <span className="custom-checkbox">
                      {selectedItems.some((el) => el.id === result.id) && (
                        <span className="custom-checkbox__checked" />
                      )}
                    </span>
                    {result.label}
                  </button>
                </li>
              ))}
            </InfiniteScroll>
          </div>
        )}
      </div>
      <ul
        className={`search-filter__chosen-list ${filterType === 'tags' ? 'chosen-list-tags' : ''}`}
      >
        {selectedItems.map((item) => (
          <li key={item.id} className="search-filter__chosen-item">
            {item.color !== undefined && (
              <span style={{ backgroundColor: item.color }}>{true}</span>
            )}
            {item.label}
            <button
              className="search-filter__delete"
              type="button"
              onClick={() => {
                onChange(selectedItems.filter((el) => el.id !== item.id));
              }}
            >
              <Delete />
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SearchFilter;
