import './Setting.scss';
import { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import { ValidationError } from 'yup';
import SettingAvatar from './component/SettingAvatar';
import ProfileForm from './component/form/ProfileForm';
import GeneralForm from './component/form/GeneralForm';
import ChangePassword from './component/changePassword/ChangePassword';
import { useAppSelector } from '../../../hook/useAppSelector';
import { useAppDispatch } from '../../../hook/useAppDispatch';
import authService from '../../../service/auth/authService';
import { UserProfile } from '../../../service/user/userService';
import { getUserProfile, updateUserProfile } from '../../../redux/slice/userSlice';
import { uploadImage } from '../../../redux/slice/s3Slice';
import { setMessage } from '../../../redux/slice/messageSlice';
import { convertToErrorMessage } from '../../../service/message/messageConverter';
import { updateUserInfoSchema } from '../../../service/validation/schema/profile/updateUserInfoSchema';
import { REGISTRATION_FORM_CONF } from '../../../service/validation/schema/registration/registrationSchema';
import ParametersForm from './component/form/ParametersForm';
import ExpandableSettingsList from './component/advancedSetting/ExpandableAdvancedSettingList';
import Logout from './component/logout/Logout';

interface SettingProps {
  onLanguageChange: (newLocale: string) => void;
}

const TIMER_SHOW_MESSAGE = 3000;

const Setting = ({ onLanguageChange }: SettingProps) => {
  const user: UserProfile = useAppSelector((state) => state.user);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [showSuccesMessage, setShowSuccesMessage] = useState(false);
  const { userId } = authService.getCurrentUser();
  const dispatch = useAppDispatch();
  let timer: NodeJS.Timeout;

  useEffect(() => () => clearTimeout(timer), [setShowSuccesMessage]);

  useEffect(() => {
    dispatch(getUserProfile(userId));
  }, [dispatch, userId]);

  const uploadSelectedFile = async () => {
    if (selectedFile) {
      try {
        return await dispatch(uploadImage(selectedFile)).unwrap();
      } catch (error) {
        dispatch(setMessage(convertToErrorMessage(error)));
      }
    }
  };

  const updateUserInfo = async (updateUserData: UserProfile) => {
    try {
      setLoading(true);

      const isFormChanged = JSON.stringify(updateUserData) !== JSON.stringify(user);
      if (!isFormChanged) {
        return setLoading(false);
      }

      const updatedProfile = { ...updateUserData };
      const uploadedImage = await uploadSelectedFile();
      if (uploadedImage !== undefined) {
        updatedProfile.imageUrl = uploadedImage;
      }

      await updateUserInfoSchema
        .validate(updateUserData, REGISTRATION_FORM_CONF)
        .then(() => {
          dispatch(updateUserProfile({ userId, updatedProfile }))
            .unwrap()
            .then(() => {
              setShowSuccesMessage(true);
              timer = setTimeout(() => setShowSuccesMessage(false), TIMER_SHOW_MESSAGE);
            })
            .catch((error) => {
              dispatch(setMessage(convertToErrorMessage(error)));
            })
            .finally(() => {
              setLoading(false);
            });
        })
        .catch((errors: ValidationError) => {
          if (errors.inner.length > 0) {
            const errorMessages = errors.inner.map((error) => error.message);
            dispatch(setMessage({ type: null, text: errorMessages }));
          }
          setLoading(false);
        });
    } catch (error) {
      dispatch(setMessage(convertToErrorMessage(error)));
      setLoading(false);
    }
  };

  return (
    <Formik initialValues={user} onSubmit={(values) => updateUserInfo(values)}>
      {(formikProps) => (
        <Form data-testid="form-setting-profile">
          <div className="profile-window setting">
            <h2 className="profile-window__title">
              <FormattedMessage id="profile.settings" />
            </h2>
            <div className="profile-window__content">
              <SettingAvatar formikProps={formikProps} setSelectedFile={setSelectedFile} />
              <div className="profile-window__user">
                <ProfileForm formikProps={formikProps} />
                <ParametersForm formikProps={formikProps} />
              </div>
              <GeneralForm formikProps={formikProps} onLanguageChange={onLanguageChange} />
              <div className="profile-window__buttons">
                <ChangePassword />
                <ExpandableSettingsList />
                <Logout />
              </div>
            </div>
            {showSuccesMessage ? (
              <p className="window__subtitle--confirm ">
                <FormattedMessage id="profile.update.info" />
              </p>
            ) : (
              <button
                className="profile-window__save-btn"
                type="submit"
                disabled={loading || showSuccesMessage}
              >
                {loading ? (
                  <span className="spinner-border spinner-border-sm" />
                ) : (
                  <FormattedMessage id="form.label.save" />
                )}
              </button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Setting;
