/* eslint-disable-next-line */
import Cookies from 'universal-cookie';

export const cookies = new Cookies();

const getCookies = (name: string): string => cookies.get(name);

const getAllCookies = (): object => cookies.getAll();

const setCookies = (name: string, value: string, minutes: number) => {
  const expires = new Date();
  expires.setTime(expires.getTime() + minutes * 60 * 1000);
  cookies.set(name, value, { expires, path: '/' });
};

const deleteCookies = (name: string) => {
  cookies.remove(name, { path: '/' });
};

const deleteAllCookies = () => {
  const allCookies = getAllCookies();

  Object.keys(allCookies).forEach((cookieName) => {
    deleteCookies(cookieName);
  });
};

const cookieService = {
  getCookies,
  getAllCookies,
  setCookies,
  deleteCookies,
  deleteAllCookies,
};

export default cookieService;
