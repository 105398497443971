import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { APP_PATH } from '../../common/constant/pathConstant';
import './LegalLinks.scss';

function LegalLinks() {
  return (
    <div className="legal-login">
      <Link to={APP_PATH.PRIVACY_POLICY} target='_blank' className="legal-login__privacy">
        <FormattedMessage id="legal.privacy.title" />
      </Link>
      |
      <Link to={APP_PATH.TERMS_AND_CONDITIONS} target='_blank' className="legal-login__terms">
        <FormattedMessage id="legal.terms.title" />
      </Link>
    </div>
  );
}

export default LegalLinks;
