import React, { useEffect, useState } from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';
import { FormattedMessage } from 'react-intl';
import { isMobile } from 'react-device-detect';
import { useSearchParams } from 'react-router-dom';
import Search from '../../../component/search/Search';
import MealItem from './component/MealItem';

import './MealList.scss';
import Filter from '../../../asset/icons/filter.svg';

import { useAppDispatch } from '../../../hook/useAppDispatch';
import {
  clearSearchState,
  getAvailableFiltersDataToSelect,
  initialAvailableFilter as initialMealFilters,
  searchMeals,
  setMealSortField,
  setMealSortOrder,
  setSelectedSearchObject,
} from '../../../redux/slice/mealSlice';
import { useAppSelector } from '../../../hook/useAppSelector';

import LoadingSpinner from '../../../component/spinner/LoadingSpinner';
import { BACKEND_MEALS_PARAMS } from '../../../common/constant/pathConstant';
import MealListFilter from './filter/MealListFilter';
import mealMapperService from '../../../service/mapper/meal/mealMapperService';
import filterService from '../../../service/filter/filterService';
import { useListHeight } from '../../../hook/useListHeight';
import { SortingFilters, SortOrder } from '../../../common/constant/interface/interfaces';
import { RootState } from '../../../redux';
import SortOrderSelect from '../../../component/sorting/SortOrderSelect';

const MEALS_SEARCH_COUNT_SIZE = 15;

const selectSortFieldOptions = [
  { label: <FormattedMessage id='sort.option.name' />, value: 'name' },
  { label: <FormattedMessage id='sort.option.time' />, value: 'createdAt' },
];

const MealList = () => {
  const dispatch = useAppDispatch();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [appliedUrlSearchParam, setAppliedUrlSearchParam] = useSearchParams();
  const { listHeight, listRef } = useListHeight();

  const searchMealsResult = useAppSelector((state) => state.meal.search.searchResults);
  const sortingFilters = useAppSelector(
    (state: RootState) => state.meal.search.sortingSearchParam,
  );
  const appliedSearchObj = useAppSelector((state) => state.meal.search.appliedSearchParam);
  const nextSearchMealsCursorId = useAppSelector((state) => state.meal.search.searchNextCursorId);
  const availableSearchObj = useAppSelector((state) => state.meal.search.availableSearchParam);
  const selectedSearchObj = useAppSelector((state) => state.meal.search.selectedSearchParam);
  const selectedSearchString = useAppSelector((state) => state.meal.search.searchParam);

  const searchMealsWithParams = (
    searchObj = selectedSearchObj,
    availableSearch = availableSearchObj,
    sortingFiltersToSearch = sortingFilters,
    cursorId: string = '',
    override = true,
  ) => {
    dispatch(searchMeals({
      selectedParam: searchObj,
      availableParam: availableSearch,
      sortingFilters: sortingFiltersToSearch,
      size: MEALS_SEARCH_COUNT_SIZE,
      cursorId,
      overridePrev: override,
    }));
  };

  const handleMealSearch = (
    sortingFiltersToSearch: SortingFilters = sortingFilters,
    cursorId = '',
    override = true,
  ) => {
    searchMealsWithParams(
      selectedSearchObj,
      availableSearchObj,
      sortingFiltersToSearch,
      cursorId,
      override,
    );
  };

  const handleMealSearchWithParam = (
    searchObj = selectedSearchObj,
    availableSearch = availableSearchObj,
    sortingFiltersToSearch = sortingFilters,
  ) => {
    if (isMobile) {
      setIsFilterOpen(false);
    }
    searchMealsWithParams(searchObj, availableSearch, sortingFiltersToSearch);
  };

  const fetchFiltersAndPopulateSearchFromUrl = async () => {
    if (availableSearchObj !== initialMealFilters) return;

    try {
      const fetchedFilters = await dispatch(getAvailableFiltersDataToSelect()).unwrap();

      if (appliedUrlSearchParam) {
        // eslint-disable-next-line max-len
        const appliedSearchObject = await filterService.mapSearchParamsToObject(appliedUrlSearchParam.toString());
        // eslint-disable-next-line max-len
        const selectedSearchObject = mealMapperService.mapFilterApiResponseToMealSearchSelectedParam(fetchedFilters);
        const mergedFilters = { ...selectedSearchObject, ...appliedSearchObject };

        dispatch(setSelectedSearchObject(mergedFilters));
        handleMealSearchWithParam(mergedFilters, fetchedFilters);
      } else if (!searchMealsResult.length) {
        handleMealSearch();
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchFiltersAndPopulateSearchFromUrl();

    return () => {
      dispatch(clearSearchState());
    };
  }, []);

  useEffect(() => {
    setAppliedUrlSearchParam(selectedSearchString);
  }, [selectedSearchString]);

  const loadMore = () => {
    if (!nextSearchMealsCursorId) return;
    handleMealSearch(sortingFilters, nextSearchMealsCursorId, false);
  };

  const handleNameSearch = (query: string) => {
    const appliedFilters = mealMapperService.mapToMealSearchSelectedParam(appliedSearchObj);
    const updatedSearchObj = {
      ...appliedFilters,
      name: query,
    };

    dispatch(setSelectedSearchObject({
      ...selectedSearchObj,
      name: query,
    }));

    searchMealsWithParams(updatedSearchObj, availableSearchObj);
  };

  const handleSortFieldChange = (newSortField: string) => {
    dispatch(setMealSortField(newSortField));
    handleMealSearch({ sortOrder: sortingFilters.sortOrder, sortField: newSortField });
  };

  const handleOrderChange = () => {
    const newSortOrder = sortingFilters.sortOrder === SortOrder.ASC
      ? SortOrder.DESC : SortOrder.ASC;
    dispatch(setMealSortOrder(newSortOrder));
    handleMealSearch({ sortOrder: newSortOrder, sortField: sortingFilters.sortField });
  };

  return (
    <section className="meal">
      <div className={`meal__left-container ${isFilterOpen ? 'with-filter-open' : ''}`}>
        <div className="meal__search">
          <Search
            searchQueryKey={BACKEND_MEALS_PARAMS.NAME}
            onSearch={handleNameSearch}
          />
          <button
            type="button"
            className="meal__filters-button animated-gradient-btn"
            onClick={() => setIsFilterOpen(!isFilterOpen)}
            aria-label="meal-filter-button"
            data-testid="meal-filter-button"
          >
            <img src={Filter} alt="filter" />
            <FormattedMessage id="meal.list.filters" />
          </button>
        </div>
        <SortOrderSelect
          options={selectSortFieldOptions}
          onOrderClick={handleOrderChange}
          onSortChange={handleSortFieldChange}
        />
        <div
          className="meal__list"
          ref={listRef}
          data-testid="meal-list"
        >
          <InfiniteScroll
            className='meal__infinite-scroll'
            dataLength={searchMealsResult.length}
            hasMore={nextSearchMealsCursorId !== undefined}
            height={listHeight}
            next={loadMore}
            loader={<LoadingSpinner />}
          >
            {(searchMealsResult).map((meal) => (
              <MealItem
                prepTime={meal.prepareTimeMinutes}
                cookTime={meal.cookTimeMinutes}
                weight={meal.units[0].unit.value}
                key={meal.id}
                image={meal.images?.[0]?.url || ''}
                title={meal.name}
                tags={meal.tags}
                description={meal.description}
                kcal={meal.units[0].nutrition!.calorie}
                proteins={meal.units[0].nutrition!.protein}
                fats={meal.units[0].nutrition!.fat}
                carbohydrates={meal.units[0].nutrition!.carbo}
                mealId={meal.id}
              />
            ))}
          </InfiniteScroll>
        </div>
      </div>
      <div className={`meal__right-container ${isFilterOpen && 'open'}`}>
        <div
          className={`meal__right-top-container ${isFilterOpen && 'open'}`}
          data-testid="meal-top-container"
        >
          <MealListFilter
            handleSearch={() => handleMealSearch()}
            onFilterClick={() => setIsFilterOpen(!isFilterOpen)}
          />
        </div>
      </div>
    </section>
  );
};

export default MealList;
