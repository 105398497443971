import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import InfiniteScroll from 'react-infinite-scroll-component';
import RationReviewForm from './rationReviewForm/RationReviewForm';
import RationReviewItem from './rationReviewItem/RationReviewItem';
import { useAppDispatch } from '../../../../hook/useAppDispatch';
import { useAppSelector } from '../../../../hook/useAppSelector';
import {
  deleteReviewFromRation,
  getAllRationReviews,
  sendRationReview,
  updateRationReview,
} from '../../../../redux/slice/rationSlice';
import LoadingSpinner from '../../../../component/spinner/LoadingSpinner';

interface RationReviewsProps {
  rationId: string;
}

const RationReviews: React.FC<RationReviewsProps> = ({ rationId }) => {
  const dispatch = useAppDispatch();
  const rationReviews = useAppSelector(
    (state) => state.ration.rationById.reviews.availableReviews,
  );
  const nextCursorId = useAppSelector((state) => state.ration.rationById.reviews.nextCursorId);
  const REVIEWS_PAGINATION_COUNT_SIZE = 10;
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [newReviewId, setNewReviewId] = useState<string | null>(null);
  const newReviewRef = useRef<HTMLDivElement | null>(null);

  const handleReviewsDispatch = (cursorId: string = '', overridePrev: boolean = true) => {
    dispatch(getAllRationReviews({
      rationId, cursorId, REVIEWS_PAGINATION_COUNT_SIZE, overridePrev,
    }))
      .unwrap()
      .then((res) => {
        if (res.response.content.length < REVIEWS_PAGINATION_COUNT_SIZE) {
          setHasMore(false);
        }
      })
      .catch((error: any) => {
        console.error('Error fetching rations reviews:', error);
      });
  };

  useEffect(() => {
    handleReviewsDispatch();
  }, [rationId]);

  useEffect(() => {
    if (newReviewId && newReviewRef.current) {
      newReviewRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      setNewReviewId(null);
    }
  });

  const handleDeleteReview = (reviewId: string) => {
    dispatch(deleteReviewFromRation({ rationId, reviewId }))
      .unwrap()
      .catch((error: any) => {
        console.error('Error deleting ration review:', error);
      });
  };

  const handleSubmitReview = (inputValue: string, selectedRating: number, language: string) => {
    dispatch(
      sendRationReview({
        rationId,
        reviewItem: {
          rationId,
          text: inputValue,
          rating: selectedRating,
          language,
        },
      }),
    )
      .unwrap()
      .then((res) => {
        setNewReviewId(res.id);
      });
  };

  const handleEditReview = (
    reviewId: string,
    inputValue: string,
    selectedRating: number,
    language: string,
  ) => {
    dispatch(
      updateRationReview({
        rationId,
        reviewId,
        reviewItem: {
          rationId,
          reviewId,
          text: inputValue,
          rating: selectedRating,
          language,
        },
      }),
    );
  };

  const loadMoreReviews = () => {
    if (nextCursorId && hasMore) {
      handleReviewsDispatch(nextCursorId, false);
    }
  };

  return (
    <div className="ration-reviews" data-testId="ration-reviews">
      <div className="ration-reviews__container">
        {rationReviews.length ? (
          <InfiniteScroll
            dataLength={rationReviews.length}
            next={loadMoreReviews}
            hasMore={hasMore}
            height="550px"
            className="ration-reviews__inifinite-scroll"
            loader={<LoadingSpinner data-testid="loading-spinner" />}
          >
            {rationReviews.map((review) => (
              <div
                className='ration-reviews__item'
                key={review.id}
                ref={review.id === newReviewId ? newReviewRef : null}
              >
                <RationReviewItem
                  key={review.id}
                  review={review}
                  onDelete={handleDeleteReview}
                  onEdit={handleEditReview}
                />
              </div>
            ))}
          </InfiniteScroll>
        ) : (
          <div className="ration-reviews__no-reviews">
            <FormattedMessage id="ration.reviews.empty" />
          </div>
        )}
      </div>

      <RationReviewForm onSubmit={handleSubmitReview} />
    </div>
  );
};

export default RationReviews;
