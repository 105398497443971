import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import MyRationOptions from './component/myRation/option/MyRationOptions';
import './Dashboard.scss';
import CalendarDatePicker from './component/calendar/CalendarDatePicker';
import MyRationsList from './component/myRation/MyRationsList';
import GenerateRation from './component/myRation/GenerateRation';
import RationsSlider from './component/rationsSlider/RationsSlider';
import { useAppDispatch } from '../../hook/useAppDispatch';
import { getMyRationForDay, resetState } from '../../redux/slice/myRationSlice';
import { useAppSelector } from '../../hook/useAppSelector';
import LoadingSpinner from '../../component/spinner/LoadingSpinner';
import AnalyticExpand from './component/analytics/AnalyticExpand';
import { nutritionColors } from '../../common/constant/colorsConstant';
import { APP_PATH } from '../../common/constant/pathConstant';
import { DATE_PICKER_FORMAT } from '../../common/constant/dateFormat';

const isDateFormat = (segment: string): boolean => {
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
  return dateRegex.test(segment);
};

const getLastPathSegment = (url: string): string => {
  const segments = url.split('/').filter(Boolean);
  return segments[segments.length - 1];
};

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [showGenerateRation, setShowGenerateRation] = useState(false);
  const myRations = useAppSelector((store) => store.myRation.myRations);
  const formatSelectedDate = format(selectedDate, DATE_PICKER_FORMAT);
  const findRationOnDay = myRations.find((item) => item.day === formatSelectedDate);

  const bigCircleNutrition = [
    {
      valueEaten: findRationOnDay ? findRationOnDay.myRation.nutritionEaten.calorie : 0,
      valueForDay: findRationOnDay ? findRationOnDay.myRation.nutrition.calorie : 0,
      intlTitle: 'calories',
      className: 'calories',
      color: nutritionColors.calories,
    },
  ];

  const smallCircleNutrition = [
    {
      valueEaten: findRationOnDay ? findRationOnDay.myRation.nutritionEaten.protein : 0,
      valueForDay: findRationOnDay ? findRationOnDay.myRation.nutrition.protein : 0,
      intlTitle: 'dashboard.title.proteins',
      className: 'protein',
      color: nutritionColors.proteins,
    },
    {
      valueEaten: findRationOnDay ? findRationOnDay.myRation.nutritionEaten.fat : 0,
      valueForDay: findRationOnDay ? findRationOnDay.myRation.nutrition.fat : 0,
      intlTitle: 'dashboard.title.fats',
      className: 'fat',
      color: nutritionColors.fats,
    },
    {
      valueEaten: findRationOnDay ? findRationOnDay.myRation.nutritionEaten.carbo : 0,
      valueForDay: findRationOnDay ? findRationOnDay.myRation.nutrition.carbo : 0,
      intlTitle: 'dashboard.title.carbohydrates',
      className: 'carbo',
      color: nutritionColors.carbs,
    },
  ];

  const hasMealsOnDay = findRationOnDay?.myRation.foodTypes.some(
    (items) => items.items.length > 0,
  ) || false;

  useEffect(() => {
    setShowGenerateRation(false);
  }, [findRationOnDay, hasMealsOnDay]);

  useEffect(() => {
    const lastPathSegment = getLastPathSegment(location.pathname);
    if (isDateFormat(lastPathSegment)) {
      setSelectedDate(new Date(lastPathSegment));
    } else {
      navigate(`${APP_PATH.DASHBOARD}/${format(new Date(), DATE_PICKER_FORMAT)}`);
    }

    return () => {
      dispatch(resetState());
    };
  }, []);

  const getMyRationOnDay = async () => {
    if (!findRationOnDay) {
      setIsLoading(true);
      await dispatch(getMyRationForDay(formatSelectedDate))
        .unwrap()
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleDatePickerChangeDate = (date: Date) => {
    setSelectedDate(date);
    navigate(`${APP_PATH.DASHBOARD}/${format(date, DATE_PICKER_FORMAT)}`);
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="loading">
          <LoadingSpinner />
        </div>
      );
    }

    if ((findRationOnDay && hasMealsOnDay) || showGenerateRation) {
      if (findRationOnDay) {
        return <MyRationsList myRationOnDay={findRationOnDay} />;
      }
    }

    return <GenerateRation setShowGenerateRation={setShowGenerateRation} />;
  };
  return (
    <div
      className="profile-window dashboard"
      id="dashboard"
      data-testid="dashboard"
    >
      <div className="dashboard__l-side l-side">
        <div className="l-side__header">
          <h2 className="l-side__title title-side">
            <FormattedMessage id="dashboard.title.myRation" />
          </h2>
          <div className="l-side__actions">
            <CalendarDatePicker
              selectedDate={selectedDate}
              setSelectedDate={handleDatePickerChangeDate}
              getMyRationOnDay={getMyRationOnDay}
            />
            <MyRationOptions selectedDate={selectedDate} />
          </div>
        </div>
        {renderContent()}
      </div>
      <hr />
      <div className="dashboard__r-side r-side">
        <h3 className="r-side__title title-side">
          <FormattedMessage id="dashboard.title.dailyAnalytics" />
        </h3>
        <div className="r-side__bars">
          <div id="circle-big-container">
            {bigCircleNutrition.map((item) => (
              <AnalyticExpand
                key={item.className}
                valueEaten={item.valueEaten}
                valueForDay={item.valueForDay}
                intlTitle={item.intlTitle}
                className={item.className}
                color={item.color}
              />
            ))}
          </div>
          <div id="circle-small-container">
            {smallCircleNutrition.map((item) => (
              <AnalyticExpand
                key={item.className}
                valueEaten={item.valueEaten}
                valueForDay={item.valueForDay}
                intlTitle={item.intlTitle}
                className={item.className}
                color={item.color}
              />
            ))}
          </div>
        </div>
        <RationsSlider />
      </div>
    </div>
  );
};

export default Dashboard;
