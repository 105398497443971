import React, { useCallback, useEffect, useState } from 'react';
import './Search.scss';
import { debounce } from 'lodash';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import IconSearch from '../../asset/icons/search.svg';

interface SearchProps {
  onSearch?: (query: string) => void;
  searchQueryKey?: string;
}

const Search = ({
  onSearch = () => {},
  searchQueryKey,
}: SearchProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState(searchParams.get(searchQueryKey ?? '') ?? '');

  const debouncedSearch = useCallback(
    debounce((query: string) => {
      onSearch(query);
      setSearchParams((prev) => {
        prev.set(searchQueryKey ?? '', query);
        return prev;
      }, { replace: true });
    }, 300),
    [onSearch],
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery = e.target.value;
    setSearchQuery(newQuery);
    debouncedSearch(newQuery);
  };

  useEffect(() => () => debouncedSearch.cancel(), [debouncedSearch]);

  const intl = useIntl();
  const placeholderText = intl.formatMessage({ id: 'search.placeholder' });

  return (
    <div className="search">
      <input
        className="search__input"
        type="search"
        placeholder={placeholderText}
        value={searchQuery}
        onChange={handleInputChange}
      />
      <img className="search__icon" src={IconSearch} alt="search" />
    </div>
  );
};

export default Search;
