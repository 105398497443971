const nutritionColors = {
  calories: '#ee7e7a',
  proteins: '#67db87',
  fats: '#ffc670',
  carbs: '#7099ff',
};

const PROGRESS_BAR_COLORS = {
  DEFAULT: '#06c78c',
  EXCEEDED: 'linear-gradient(90deg, rgba(6,199,140,1) 73%, rgba(255,250,0,1) 89%, rgba(255,117,0,1) 98%)',
};

export { nutritionColors, PROGRESS_BAR_COLORS };
