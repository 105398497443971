import React from 'react';
import { FormattedMessage } from 'react-intl';
import authService from '../../../../../service/auth/authService';
import LogoutIcon from '../../../../../asset/icons/profile/logout.svg';

const Logout = () => {
  const handleLogout = async () => {
    await authService.logout();
  };

  return (
    <button
      className="setting-btn"
      data-testid="logout-btn"
      type="button"
      onClick={handleLogout}
    >
      <FormattedMessage id="button.logout" />
      <img src={LogoutIcon} alt="Logout" />
    </button>
  );
};

export default Logout;
