import { useEffect, useRef, useState } from 'react';

export const useListHeight = () => {
  const [listHeight, setListHeight] = useState(window.innerHeight);
  const listRef = useRef<HTMLDivElement>(null);

  const updateHeight = () => {
    if (listRef.current) {
      const offsetTop = listRef.current.getBoundingClientRect().top;
      const offsetBottomNavigation = 70;
      setListHeight(window.innerHeight - (offsetTop + offsetBottomNavigation));
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateHeight);
    updateHeight();

    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  return { listHeight, listRef };
};
