import {
  useEffect, useState, useCallback,
} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import MediaQuery from 'react-responsive';
import { useSearchParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { debounce } from 'lodash';
import ChoosePic from '../../../asset/icons/ingredient/choose.svg';
import Search from '../../../component/search/Search';
import IngredientInfo from '../IngredientInfo';
import './IngredientList.scss';
import { useAppDispatch } from '../../../hook/useAppDispatch';
import {
  getIngredientGroups,
  getIngredients,
  getIngredientById,
  searchAndSortIngredients,
  setIngredientSortOrder,
  setIngredientSortField,
} from '../../../redux/slice/ingredientSlice';
import { useAppSelector } from '../../../hook/useAppSelector';
import LoadingSpinner from '../../../component/spinner/LoadingSpinner';
import { RootState } from '../../../redux';
import { MOBILE_WIDTH } from '../../../common/constant/deviceCheckpoints';
import { BACKEND_INGREDIENTS_PARAMS } from '../../../common/constant/pathConstant';
import {
  Ingredient,
  IngredientGroup,
  SortOrder,
} from '../../../common/constant/interface/interfaces';
import SelectSortingFilter from '../../../component/sorting/SortOrderSelect';
import { useListHeight } from '../../../hook/useListHeight';

const INGREDIENTS_SEARCH_COUNT_SIZE = 20;
const selectSortFieldOptions = [
  { label: <FormattedMessage id='sort.option.name' />, value: 'name' },
  { label: <FormattedMessage id='sort.option.time' />, value: 'createdAt' },
];

const IngredientList = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedGroup, setSelectedGroup] = useState<IngredientGroup | null>(null);
  const [selectedIngredient, setSelectedIngredient] = useState<Ingredient | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>(
    searchParams.get(BACKEND_INGREDIENTS_PARAMS.NAME) || '',
  );
  const [loading, setLoading] = useState(false);
  const { listHeight, listRef } = useListHeight();

  const ingredientGroups = useAppSelector((state: RootState) => state.ingredient.ingredientGroups);
  const nextGroupCursorId = useAppSelector(
    (state: RootState) => state.ingredient.nextGroupCursorId,
  );
  const searchResults = useAppSelector((state: RootState) => state.ingredient.search.searchResults);
  const nextSearchCursorId = useAppSelector(
    (state: RootState) => state.ingredient.search.searchNextCursorId,
  );
  const sortOrder = useAppSelector(
    (state: RootState) => state.ingredient.search.sortingSearchParam.sortOrder,
  );
  const sortField = useAppSelector(
    (state: RootState) => state.ingredient.search.sortingSearchParam.sortField,
  );

  useEffect(() => {
    if (!ingredientGroups.length) {
      dispatch(
        getIngredientGroups({ cursorId: '', size: INGREDIENTS_SEARCH_COUNT_SIZE, level: 1 }),
      );
    }
  }, [dispatch, ingredientGroups]);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.style.overflow = searchQuery ? 'hidden' : 'visible';
    }
  }, [searchQuery]);

  useEffect(() => {
    const findIngredientGroup = (ingredient: Ingredient) => ingredientGroups.find(
      (el) => el.id === ingredient.group?.id,
    ) || null;

    const fetchIngredientById = async (ingredientId: string) => {
      const response = await dispatch(getIngredientById(ingredientId));
      const ingredient = response.payload as Ingredient;

      setSelectedIngredient(ingredient);
      const ingredientGroup = findIngredientGroup(ingredient);
      setSelectedGroup(ingredientGroup);

      const loadIngredientsForGroup = (groupId: string) => {
        dispatch(
          getIngredients({
            cursorId: '',
            size: INGREDIENTS_SEARCH_COUNT_SIZE,
            groupId,
          }),
        );
      };

      if (ingredientGroup && !ingredientGroup.ingredients) {
        loadIngredientsForGroup(ingredientGroup.id);
      }
    };

    const ingredientId = searchParams.get(BACKEND_INGREDIENTS_PARAMS.INGREDIENT_ID);

    if (ingredientId && !selectedIngredient) {
      fetchIngredientById(ingredientId);
    }
  }, [dispatch, searchParams, selectedIngredient, ingredientGroups]);

  useEffect(() => {
    if (selectedGroup && !selectedGroup.ingredients) {
      dispatch(
        getIngredients({
          cursorId: '',
          size: INGREDIENTS_SEARCH_COUNT_SIZE,
          groupId: selectedGroup.id,
        }),
      );
    }
  }, [selectedGroup, dispatch]);

  const search = (
    sortField: string,
    sortOrder: SortOrder,
    searchQuery: string,
    cursorId: string,
    overridePrev: boolean,
    size: number = INGREDIENTS_SEARCH_COUNT_SIZE,
  ) => {
    setLoading(true);
    dispatch(
      searchAndSortIngredients({
        sortingFilters: { sortField, sortOrder },
        name: searchQuery,
        cursorId,
        size,
        overridePrev,
      }),
    ).finally(() => setLoading(false));
  };

  useEffect(() => {
    if (searchQuery) {
      search(sortField, SortOrder.ASC, searchQuery, '', true);
    }
  }, [dispatch, searchQuery]);

  const debouncedSearch = useCallback(
    debounce((query: string) => setSearchQuery(query), 300),
    [],
  );

  const handleSortChange = (newSortField: string) => {
    dispatch(setIngredientSortField(newSortField));
    search(newSortField, sortOrder, searchQuery, '', true);
  };

  const handleOrderChange = () => {
    const newSortOrder = sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
    dispatch(setIngredientSortOrder(newSortOrder));
    search(sortField, newSortOrder, searchQuery, '', true);
  };

  const loadMoreSearchResults = () => {
    if (nextSearchCursorId) {
      search(sortField, sortOrder, searchQuery, nextSearchCursorId, false);
    }
  };

  const loadMoreIngredientGroups = () => {
    if (nextGroupCursorId) {
      dispatch(
        getIngredientGroups({
          cursorId: nextGroupCursorId,
          size: INGREDIENTS_SEARCH_COUNT_SIZE,
          level: 1,
        }),
      );
    }
  };

  const onIngredientClickHandler = (ingredient: Ingredient) => {
    setSelectedIngredient(ingredient);
    setSearchParams(
      (prev) => {
        prev.set(BACKEND_INGREDIENTS_PARAMS.INGREDIENT_ID, ingredient.id);
        return prev;
      },
      { replace: true },
    );

    if (window.innerWidth <= MOBILE_WIDTH) {
      setTimeout(() => {
        const section = document.querySelector(`[id="${ingredient.id}"]`);
        section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 300);
    }
  };

  const onGroupClickHandler = (group: IngredientGroup) => {
    if (selectedGroup?.id !== group.id) {
      setSelectedGroup(group);
      if (!group.ingredients) {
        dispatch(
          getIngredients({ cursorId: '', size: INGREDIENTS_SEARCH_COUNT_SIZE, groupId: group.id }),
        );
      }
    }
  };

  return (
    <section className='ingredient'>
      <div className='ingredient__left-container'>
        <Search
          searchQueryKey={BACKEND_INGREDIENTS_PARAMS.NAME}
          onSearch={debouncedSearch}
        />
        <SelectSortingFilter
          options={selectSortFieldOptions}
          onOrderClick={handleOrderChange}
          onSortChange={handleSortChange}
        />
        <div
          className='ingredient__list'
          ref={listRef}
        >
          {searchQuery ? (
            <InfiniteScroll
              dataLength={searchResults?.length || 0}
              next={loadMoreSearchResults}
              hasMore={!!nextSearchCursorId}
              height={listHeight}
              loader={loading && <LoadingSpinner />}
            >
              <div className='ingredient__search-results'>
                {searchResults?.map((ingredient) => (
                  <div key={ingredient.id}>
                    <button
                      type='button'
                      onClick={() => onIngredientClickHandler(ingredient)}
                      className={`ingredient__sublist-item ${
                        selectedIngredient?.name === ingredient.name
                          ? 'ingredient__sublist-item_active'
                          : ''
                      }`}
                    >
                      {ingredient.name}
                    </button>
                    <div
                      className={`ingredient__info-wrapper ${
                        selectedIngredient?.name === ingredient.name
                          ? 'ingredient__info-wrapper_active'
                          : ''
                      }`}
                    >
                      {selectedIngredient && (
                        <MediaQuery maxWidth={MOBILE_WIDTH}>
                          <IngredientInfo ingredient={selectedIngredient} />
                        </MediaQuery>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </InfiniteScroll>
          ) : (
            <InfiniteScroll
              dataLength={ingredientGroups.length}
              next={loadMoreIngredientGroups}
              hasMore={!!nextGroupCursorId}
              height={listHeight}
              loader={loading && <LoadingSpinner />}
            >
              {ingredientGroups.map((group) => (
                <div key={group.id}>
                  <button
                    type='button'
                    onClick={() => onGroupClickHandler(group)}
                    className={`ingredient__group-item ${
                      selectedGroup?.id === group.id ? 'ingredient__group-item_active' : ''
                    }`}
                    id={`ingredient__group-item_${group.id}`}
                  >
                    <span>{group.name}</span>
                  </button>
                  <div
                    className={`ingredient__sublist ${
                      selectedGroup?.id === group.id ? 'ingredient__sublist_active' : ''
                    }`}
                  >
                    <div className='ingredient__sublist-item-inner'>
                      {group.ingredients?.map((ingredient) => (
                        <button
                          key={ingredient.id}
                          type='button'
                          onClick={() => onIngredientClickHandler(ingredient)}
                          className={`ingredient__sublist-item ${
                            selectedIngredient?.id === ingredient.id
                              ? 'ingredient__sublist-item_active'
                              : ''
                          }`}
                        >
                          {ingredient.name}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </InfiniteScroll>
          )}
        </div>
      </div>
      <div className='ingredient__right-container'>
        {selectedIngredient ? (
          <MediaQuery minWidth={MOBILE_WIDTH}>
            <IngredientInfo ingredient={selectedIngredient} />
          </MediaQuery>
        ) : (
          <div className='ingredient__empty-info'>
            <p>
              <FormattedMessage id='ingredient.empty.info' />
            </p>
            <img
              src={ChoosePic}
              alt='Choose ingredient'
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default IngredientList;
