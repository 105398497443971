import { FormattedMessage } from 'react-intl';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Meal } from '../../../../../../common/constant/interface/interfaces';
import UnitSelector from '../../unitSelect/UnitSelect';
import UnitCounter from '../../unitCounter/UnitCounter';
import { APP_PATH } from '../../../../../../common/constant/pathConstant';

const MIN_SELECT_VALUE = 1;
const MAX_SELECT_VALUE = 999;

export interface MealChangeParams {
  meal: Meal;
  selectedUnitName: string;
  selectedValue: number;
}

interface AddMealItemProps {
    meal: Meal;
    selected: boolean;
    value: number;
    unitName: string;
    onValueChange: (params: MealChangeParams) => void;
    onUnitChange: (params: MealChangeParams) => void;
    onSelect: (params: MealChangeParams) => void;
}

const AddMealItem = ({
  meal, selected, value, unitName, onSelect, onValueChange, onUnitChange,
}: AddMealItemProps) => {
  const [selectedValue, setSelectedValue] = useState(value);
  const [selectedUnitName, setSelectedUnitName] = useState(unitName);

  const selectedCalorie = Math.floor(meal.units[0].nutrition.calorie);

  useEffect(() => {
    onValueChange({ meal, selectedUnitName, selectedValue });
  }, [selectedValue, meal.id]);

  useEffect(() => {
    onUnitChange({ meal, selectedUnitName, selectedValue });
  }, [selectedUnitName, meal.id]);

  return (
    <button
      type="button"
      onClick={() => onSelect({ meal, selectedUnitName, selectedValue })}
      className={`tab-items__item ${selected ? 'active' : ''}`}
    >
      <div className="tab-items__left">
        <img className="tab-items__pic" src={meal.images[0].url} alt={meal.name} />
        <div className="tab-items__descr">
          <Link
            to={`${APP_PATH.APP_MEALS}/${meal.id}`}
            title={meal.name}
          >
            {meal.name}
          </Link>
          <p>
            {selectedCalorie}
            {' '}
            <FormattedMessage id="meal.item.kcal" />
          </p>
        </div>
      </div>
      <div className="tab-items__right">
        <button
          className="tab-items__weight"
          onClick={(e) => e.stopPropagation()}
        >
          <UnitCounter
            min={MIN_SELECT_VALUE}
            max={MAX_SELECT_VALUE}
            initialValue={selectedValue}
            onChange={(newValue) => setSelectedValue(Number(newValue))}
          />
          <UnitSelector
            units={meal.units}
            onChange={(newUnitName) => setSelectedUnitName(newUnitName)}
          />
        </button>
      </div>
    </button>
  );
};

export default AddMealItem;
