import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setMessage } from './messageSlice';
import { convertToErrorMessage } from '../../service/message/messageConverter';
import ingredientService from '../../service/ingredient/ingredientService';
import {
  Ingredient, IngredientGroup, SortingFilters, SortOrder,
} from '../../common/constant/interface/interfaces';
import { initialSortingSearchParamObject } from './rationSlice';

export interface GetIngredientGroupApiRequest {
  cursorId: string;
  size: number;
  level: number;
}

export interface GetIngredientsApiRequest {
  cursorId: string;
  size: number;
  groupId: string;
}

export interface IngredientGroupApiResponse {
  content: IngredientGroup[];
  nextCursorId: string;
}

export interface IngredientsApiResponse {
  content: Ingredient[];
  nextCursorId: string;
  groupId: string;
}

export interface SearchIngredientsApiResponse {
  content: Ingredient[];
  nextCursorId: string;
}

export interface IngredientState {
  ingredientGroups: IngredientGroup[];
  nextGroupCursorId: string;
  nextIngredientCursorId: string;
  search: Search;
}

export interface Search {
  searchResults: Ingredient[];
  searchNextCursorId: string;
  sortingSearchParam: SortingFilters,
}

const initialState: IngredientState = {
  ingredientGroups: [],
  nextGroupCursorId: '',
  nextIngredientCursorId: '',
  search: {
    searchResults: [],
    searchNextCursorId: '',
    sortingSearchParam: initialSortingSearchParamObject,
  },
};

export const getIngredientGroups = createAsyncThunk<
  IngredientGroupApiResponse,
  GetIngredientGroupApiRequest,
  { rejectValue: string }
>(
  'ingredient/ingredientGroups',
  async ({ cursorId, size, level }, thunkAPI) => {
    try {
      return await ingredientService.getIngredientGroups(cursorId, size, level);
    } catch (error: any) {
      const { message } = error;
      thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getIngredients = createAsyncThunk<
  IngredientsApiResponse,
  GetIngredientsApiRequest,
  { rejectValue: string }
>(
  'ingredient/ingredients',
  async ({ cursorId, size, groupId }, thunkAPI) => {
    try {
      const res = await ingredientService.getIngredients(cursorId, size, groupId);
      return { ...res, groupId };
    } catch (error: any) {
      const { message } = error;
      thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const searchAndSortIngredients = createAsyncThunk<
  { response: SearchIngredientsApiResponse; overrideResult: boolean },
  {
    size: number,
    cursorId?: string,
    sortingFilters: SortingFilters,
    name: string,
    overridePrev: boolean,
    },
  { rejectValue: string }
>(
  'ingredient/filterIngredients',
  async ({
    size,
    cursorId = '',
    sortingFilters,
    name,
    overridePrev,
  }, thunkAPI) => {
    try {
      const response = await
      ingredientService.searchAndSortIngredients(size, cursorId, sortingFilters, name);
      return { response, overrideResult: overridePrev };
    } catch (error: any) {
      const { message } = error;
      thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getIngredientById = createAsyncThunk<
  Ingredient,
  string,
  { rejectValue: string }
>(
  'ingredient/getIngredientById',
  async (id, thunkAPI) => {
    try {
      return await ingredientService.getIngredientById(id);
    } catch (error: any) {
      const { message } = error;
      thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
      return thunkAPI.rejectWithValue(message);
    }
  },
);

const ingredientSlice = createSlice({
  name: 'ingredient',
  initialState,
  reducers: {
    setIngredientSortField: (state, action: PayloadAction<string>) => {
      state.search.sortingSearchParam.sortField = action.payload;
    },
    setIngredientSortOrder: (state, action: PayloadAction<SortOrder>) => {
      state.search.sortingSearchParam.sortOrder = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIngredientGroups.fulfilled, (state, action) => {
        state.ingredientGroups = [
          ...state.ingredientGroups,
          ...action.payload.content,
        ];
        state.nextGroupCursorId = action.payload.nextCursorId;
      })
      .addCase(getIngredients.fulfilled, (state, action) => {
        const groupIndex = state.ingredientGroups.findIndex(
          (el) => el.id === action.payload.groupId,
        );
        state.ingredientGroups[groupIndex] = {
          ...state.ingredientGroups[groupIndex],
          ingredients: action.payload.content,
        };
        state.nextIngredientCursorId = action.payload.nextCursorId;
      })
      .addCase(searchAndSortIngredients.fulfilled, (state, action) => {
        if (action.payload.overrideResult) {
          state.search.searchResults = action.payload.response.content;
          state.search.searchNextCursorId = action.payload.response.nextCursorId;
        } else {
          state.search.searchResults = [
            ...state.search.searchResults, ...action.payload.response.content,
          ];
          state.search.searchNextCursorId = action.payload.response.nextCursorId;
        }
      });
  },
});

export const { setIngredientSortField, setIngredientSortOrder } = ingredientSlice.actions;
const { reducer } = ingredientSlice;
export default reducer;
