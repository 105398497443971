import React, { useState } from 'react';
import './RationReviewItem.scss';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { Review } from '../../../../../common/constant/interface/interfaces';
import StarRating from '../../../../../component/rating/starRating/StarRating';
import { UserProfile } from '../../../../../service/user/userService';
import { useAppSelector } from '../../../../../hook/useAppSelector';
import UserAvatar from '../../../../../component/userAvatar/UserAvatar';
import { AVATAR_SIZES } from '../../../../../common/constant/sizesAvatar';
import languageService from '../../../../../service/language/languageService';
import SelectableStarRating from '../../../../../component/rating/selectableStarRating/SelectableStarRating';
import ConfirmationModal from '../../../../../component/confirmationModal/ConfirmationModal';
import { MAX_CHARACTERS_TEXTAREA } from '../../../../../common/constant/charactersMaxLength';

interface RationReviewItemProps {
  review: Review;
  onDelete: (reviewId: string) => void;
  onEdit: (reviewId: string, inputValue: string, selectedRating: number, language: string) => void;
}

const formatDate = (dateString: string) => {
  const date = new Date(dateString);

  const optionsYear: Intl.DateTimeFormatOptions = {
    year: 'numeric',
  };

  const optionsDay: Intl.DateTimeFormatOptions = {
    month: 'long',
    day: 'numeric',
  };

  const optionsHour: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };

  const formattedYear = date.toLocaleDateString(languageService.getCurrentLocale(), optionsYear);
  const formattedDay = date.toLocaleDateString(languageService.getCurrentLocale(), optionsDay);
  const formattedHour = date.toLocaleTimeString(languageService.getCurrentLocale(), optionsHour);

  return `${formattedYear}, ${formattedDay}, ${formattedHour}`;
};

const RationReviewItem: React.FC<RationReviewItemProps> = ({ review, onDelete, onEdit }) => {
  const {
    id, rating, text, createdAt, userAccount,
  } = review;
  const currentUser: UserProfile = useAppSelector((state) => state.user);
  const [isEditReview, setIsEditReview] = useState<boolean>(false);
  const [selectedRating, setSelectedRating] = useState<number>(rating);
  const [inputValue, setInputValue] = useState<string>(text);
  const [showModal, setShowModal] = useState<boolean>(false);
  const language = languageService.getCurrentLocale();
  const intl = useIntl();
  const confirmationModalTitle = intl.formatMessage({ id: 'confirmation.title' });
  const confirmationModaMessage = intl.formatMessage({ id: 'confirmation.message' });

  const getPlaceholders = (intl: IntlShape) => ({
    edit: intl.formatMessage({ id: 'ration.reviews.textarea.edit' }),
  });

  const placeholders = getPlaceholders(intl);

  const handleDeleteReview = () => {
    onDelete(id);
  };

  const handleEditReview = () => {
    onEdit(id, inputValue, selectedRating, language);
    setIsEditReview(false);
    setShowModal(false);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const onDeleteReview = () => {
    setShowModal(true);
  };

  return (
    <div className="review-item" data-testid="review-item">
      <div className="review-item__top">
        <div className="review-item__top-nickname">
          <div className="review-item__top-nickname-photo">
            <UserAvatar
              className="user-profile__avatar"
              name={userAccount.name}
              srcImage={userAccount.imageUrl}
              size={AVATAR_SIZES.MEDIUM}
            />
          </div>
          <h3>{userAccount.name}</h3>
        </div>
        <div className="review-item__top-stars">
          {isEditReview ? (
            <SelectableStarRating stars={selectedRating} onRatingChange={setSelectedRating} />
          ) : (
            <StarRating stars={selectedRating} />
          )}
        </div>
      </div>

      <div className="review-item__main">
        {isEditReview ? (
          <textarea
            name="edit review form"
            className="review-item__main-edit-textarea"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder={placeholders.edit}
            maxLength={MAX_CHARACTERS_TEXTAREA}
          />
        ) : (
          <p className='review-item__main-text'>{text}</p>
        )}
      </div>

      <div className="review-item__bottom">
        <div className="review-item__bottom-buttons">
          {isEditReview ? (
            <>
              <button data-testid="edit-review-submit" onClick={handleEditReview}>
                <FormattedMessage id="ration.reviews.button.submit" />
              </button>
              <button
                onClick={() => {
                  setIsEditReview(false);
                  setSelectedRating(rating);
                }}
              >
                <FormattedMessage id="ration.reviews.button.cancel" />
              </button>
            </>
          ) : (
            userAccount.identityProviderId === currentUser.identityProviderId && (
              <>
                <button data-testid="edit-review-button" onClick={() => setIsEditReview(true)}>
                  <FormattedMessage id="ration.reviews.button.edit" />
                </button>
                <button onClick={onDeleteReview}>
                  <FormattedMessage id="ration.reviews.button.delete" />
                </button>
              </>
            )
          )}
        </div>
        <p className="review-item__bottom-date">{formatDate(createdAt)}</p>
      </div>
      {showModal && (
        <ConfirmationModal
          handleClose={handleModalClose}
          handleConfirm={handleDeleteReview}
          title={confirmationModalTitle}
          message={confirmationModaMessage}
        />
      )}
    </div>
  );
};

export default RationReviewItem;
