import MyRationModalMeals from './MyRationModalMeals';
import { FoodType, FoodTypeEnum } from '../../../../common/constant/interface/interfaces';
import { MyRationResponse } from '../../../../redux/slice/myRationSlice';

interface MyRationsListProps {
  myRationOnDay: MyRationResponse | undefined;
  addMealToRation?: (foodType: string) => void;
  itemId?: string;
  nutritionValue?: number;
  selectedUnitName?: string;
}

export const setMealTimes = (meal: FoodTypeEnum) => {
  switch (meal) {
    case FoodTypeEnum.Breakfast:
      return { title: 'meal.list.breakfast', time: '8:00' };
    case FoodTypeEnum.Lunch:
      return { title: 'meal.list.lunch', time: '13:00' };
    case FoodTypeEnum.Dinner:
      return { title: 'meal.list.dinner', time: '18:00' };
    case FoodTypeEnum.Snacks:
      return { title: 'meal.list.snacks', time: '20:00' };
    default:
      console.error(`Requested meal wasn't matched by ${meal}`);
      return { title: '-', time: '-' };
  }
};
const MyRationsList = ({
  myRationOnDay, addMealToRation, itemId, nutritionValue, selectedUnitName,
}: MyRationsListProps) => {
  const isValidMealType = (mealName: string): mealName is FoodTypeEnum => (
    Object.values(FoodTypeEnum).includes(mealName as FoodTypeEnum)
  );
  return (
    <section className="meals-slider">
      <ul className="meals-slider__list">
        {myRationOnDay?.myRation?.foodTypes.map((meal: FoodType) => {
          const mealTimeInfo = isValidMealType(meal.name)
            ? setMealTimes(meal.name)
            : { title: '-', time: '-' };
          return (
            <li key={meal.name} className="meals-slider__item">
              <MyRationModalMeals
                name={mealTimeInfo.title}
                time={mealTimeInfo.time}
                items={meal.items}
                nutrition={meal.nutrition}
                type={meal.name}
                selectedDate={myRationOnDay.day}
                // @ts-expect-error
                addMealToRation={addMealToRation ?? null}
                itemId={itemId}
              />
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default MyRationsList;
