import '../../../style/scss/style.scss';
import '../Registration.scss';
import { Field } from 'formik';
import React, { ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import maleImage from '../../../asset/icons/male.svg';
import femaleImage from '../../../asset/icons/female.svg';
import { RegistrationFormValues, RegistrationStepProps } from '../Registration';
import FormField from '../../../component/form/formField/formikField/FormField';
import { useAppDispatch } from '../../../hook/useAppDispatch';
import { setMessage } from '../../../redux/slice/messageSlice';
import { convertToErrorMessage } from '../../../service/message/messageConverter';
import validationService from '../../../service/validation/validationService';
import StepsTracker from '../../../component/stepsTracker/StepsTracker';

const FIELD_NAMES_TO_BACKEND_VALIDATE: Array<keyof RegistrationFormValues> = ['gender', 'name'];

const Genders = {
  male: 'MALE',
  female: 'FEMALE',
};

function RegistrationStep3({
  onNextStep,
  formikProps,
  currentStep,
}: Readonly<RegistrationStepProps>) {
  const dispatch = useAppDispatch();

  const handleValidateAndSubmit = async () => {
    await validationService.validateRegistrationForm(
      formikProps,
      currentStep,
      FIELD_NAMES_TO_BACKEND_VALIDATE,
    )
      .then(() => {
        if (onNextStep) {
          onNextStep();
        }
      })
      .catch((error) => {
        dispatch(setMessage(convertToErrorMessage(error)));
      });
  };

  return (
    <div className="window registration-window">
      <StepsTracker className='step1' currentStep={currentStep - 1} />
      <h2 className="window__title registration-window__title">
        <FormattedMessage id="form.label.hello" />
      </h2>
      <p className="window__subtitle window__subtitle--registration">
        <FormattedMessage id="form.label.enterName" />
      </p>
      <div className="window__form form-window">
        <FormField placeholder="form.label.name" name="name" type="text" />
        <div className="form-window__gender gender">
          <h3 className="gender__title">
            <FormattedMessage id="form.label.indicateGender" />
          </h3>
          <div className="gender__select select-gender">
            <div className="select-gender__container">
              <Field
                data-testid="radio-male"
                type="radio"
                name="gender"
                id="male"
                value={Genders.male}
                checked={formikProps.values.gender === Genders.male}
                onChange={(e: ChangeEvent<HTMLInputElement>) => formikProps.setFieldValue('gender', e.target.value)}
              />
              <label htmlFor="male">
                <img src={maleImage} alt="Male" />
              </label>
              <h3 className="select-gender__name">
                <FormattedMessage id="male.button" />
              </h3>
            </div>
            <div className="select-gender__container">
              <Field
                data-testid="radio-female"
                type="radio"
                name="gender"
                id="female"
                value={Genders.female}
                checked={formikProps.values.gender === Genders.female}
                onChange={(e: ChangeEvent<HTMLInputElement>) => formikProps.setFieldValue('gender', e.target.value)}
              />
              <label htmlFor="female">
                <img alt="Female" src={femaleImage} />
              </label>
              <h3 className="select-gender__name">
                <FormattedMessage id="female.button" />
              </h3>
            </div>
          </div>
        </div>
        <button type="submit" className="form-window__btn" onClick={handleValidateAndSubmit}>
          <FormattedMessage id="next.button" />
        </button>
      </div>
    </div>
  );
}

export default RegistrationStep3;
