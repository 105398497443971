import React, { useEffect, useRef, useState } from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import Search from '../../../component/search/Search';
import RationItem from './component/RationItem';
import './RationList.scss';
import '../../meal/list/MealList.scss';

import { useAppDispatch } from '../../../hook/useAppDispatch';
import {
  clearSearchState,
  getAvailableFiltersDataToSelect,
  initialAvailableFilter as initialRationFilters,
  searchRations,
  setRationSortField,
  setRationSortOrder, setSelectedSearchObject,
} from '../../../redux/slice/rationSlice';
import { useAppSelector } from '../../../hook/useAppSelector';
import LoadingSpinner from '../../../component/spinner/LoadingSpinner';
import { RootState } from '../../../redux';
import Filter from '../../../asset/icons/filter.svg';
import RationListFilter from './filter/RationListFilter';
import { BACKEND_RATIONS_PARAMS } from '../../../common/constant/pathConstant';
import SelectSortingFilter from '../../../component/sorting/SortOrderSelect';
import { SortingFilters, SortOrder } from '../../../common/constant/interface/interfaces';
import rationMapperService from '../../../service/mapper/ration/rationMapperService';
import filterService from '../../../service/filter/filterService';
import { useListHeight } from '../../../hook/useListHeight';

const RATIONS_SEARCH_COUNT_SIZE = 10;

const selectSortFieldOptions = [
  { label: <FormattedMessage id="sort.option.name" />, value: 'name' },
  { label: <FormattedMessage id="sort.option.reviewCount" />, value: 'reviewCount' },
  { label: <FormattedMessage id="sort.option.appliesCount" />, value: 'appliesCount' },
  {
    label: <FormattedMessage id="sort.option.reviewAverageRating" />,
    value: 'reviewAverageRating',
  },
  { label: <FormattedMessage id="sort.option.time" />, value: 'createdAt' },
  { label: <FormattedMessage id="sort.option.durationDays" />, value: 'durationDays' },
];

const RationList = () => {
  const dispatch = useAppDispatch();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const { listHeight, listRef } = useListHeight();
  const [appliedUrlSearchParam, setAppliedUrlSearchParam] = useSearchParams();
  const selectedSearchString = useAppSelector((state) => state.ration.search.searchParam);

  const searchRationsResult = useAppSelector(
    (state: RootState) => state.ration.search.searchResults,
  );
  const sortingFilters = useAppSelector(
    (state: RootState) => state.ration.search.sortingSearchParam,
  );
  const nextSearchRationsCursorId = useAppSelector(
    (state) => state.ration.search.searchNextCursorId,
  );
  const availableSearchObj = useAppSelector(
    (state: RootState) => state.ration.search.availableSearchParam,
  );
  const selectedSearchObj = useAppSelector(
    (state: RootState) => state.ration.search.selectedSearchParam,
  );
  const appliedSearchObj = useAppSelector((state) => state.ration.search.appliedSearchParam);

  const searchRationsWithParams = (
    searchObj = selectedSearchObj,
    availableSearch = availableSearchObj,
    sortingFiltersToSearch = sortingFilters,
    cursorId: string = '',
    overridePrev = true,
  ) => {
    dispatch(searchRations({
      selectedParam: searchObj,
      availableParam: availableSearch,
      sortingFilters: sortingFiltersToSearch,
      size: RATIONS_SEARCH_COUNT_SIZE,
      cursorId,
      overridePrev,
    }));
  };

  const handleRationSearch = (
    sortingFiltersToSearch: SortingFilters = sortingFilters,
    cursorId = '',
    overridePrev = true,
  ) => {
    searchRationsWithParams(
      selectedSearchObj,
      availableSearchObj,
      sortingFiltersToSearch,
      cursorId,
      overridePrev,
    );
  };

  const handleSearch = (searchObj = selectedSearchObj, availableSearch = availableSearchObj) => {
    if (isMobile) {
      setIsFilterOpen(false);
    }
    searchRationsWithParams(searchObj, availableSearch);
  };

  const fetchFiltersAndPopulateSearchFromUrl = async () => {
    if (availableSearchObj !== initialRationFilters) return;

    try {
      const fetchedFilters = await dispatch(getAvailableFiltersDataToSelect()).unwrap();

      if (appliedUrlSearchParam) {
        // eslint-disable-next-line max-len
        const appliedSearchObject = await filterService.mapSearchParamsToObject(appliedUrlSearchParam.toString());
        // eslint-disable-next-line max-len
        const selectedSearchObject = rationMapperService.mapFilterApiResponseToRationSearchSelectedParam(fetchedFilters);
        const mergedFilters = { ...selectedSearchObject, ...appliedSearchObject };

        dispatch(setSelectedSearchObject(mergedFilters));
        handleSearch(mergedFilters, fetchedFilters);
      } else if (!searchRationsResult.length) {
        handleRationSearch();
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchFiltersAndPopulateSearchFromUrl();

    return () => {
      dispatch(clearSearchState());
    };
  }, []);

  useEffect(() => {
    setAppliedUrlSearchParam(selectedSearchString);
  }, [selectedSearchString]);

  const handleNameSearch = (query: string) => {
    const appliedFilters = rationMapperService.mapToRationSearchSelectedParam(appliedSearchObj);
    const updatedSearchObj = {
      ...appliedFilters,
      name: query,
    };

    dispatch(setSelectedSearchObject({
      ...selectedSearchObj,
      name: query,
    }));

    searchRationsWithParams(updatedSearchObj, availableSearchObj);
  };

  const loadMore = () => {
    if (!nextSearchRationsCursorId) return;
    handleRationSearch(sortingFilters, nextSearchRationsCursorId, false);
  };

  const handleSortFieldChange = (newSortField: string) => {
    dispatch(setRationSortField(newSortField));
    handleRationSearch({ sortOrder: sortingFilters.sortOrder, sortField: newSortField });
  };

  const handleOrderChange = () => {
    const newSortOrder = sortingFilters.sortOrder === SortOrder.ASC
      ? SortOrder.DESC : SortOrder.ASC;
    dispatch(setRationSortOrder(newSortOrder));
    handleRationSearch({ sortOrder: newSortOrder, sortField: sortingFilters.sortField });
  };

  return (
    <section className="ration">
      <div className={`ration__left-container ${isFilterOpen ? 'with-filter-open' : ''}`}>
        <div className="ration__search">
          <Search
            searchQueryKey={BACKEND_RATIONS_PARAMS.NAME}
            onSearch={handleNameSearch}
          />
          <button
            type="button"
            className="meal__filters-button animated-gradient-btn"
            onClick={() => setIsFilterOpen(!isFilterOpen)}
            aria-label="ration-filter-button"
            data-testid="ration-filter-button"
          >
            <img
              src={Filter}
              alt="filter"
            />
            <FormattedMessage id="ration.list.filters" />
          </button>
        </div>
        <SelectSortingFilter
          options={selectSortFieldOptions}
          onOrderClick={handleOrderChange}
          onSortChange={handleSortFieldChange}
        />
        <div
          className='ration__list'
          ref={listRef}
          data-testid='ration-list'
        >
          <InfiniteScroll
            dataLength={searchRationsResult.length}
            hasMore={nextSearchRationsCursorId !== undefined}
            height={listHeight}
            next={loadMore}
            loader={<LoadingSpinner />}
          >
            {searchRationsResult.map((ration) => (
              <RationItem
                key={ration.id}
                rationId={ration.id}
                image={ration.images[0]?.url}
                title={ration.name}
                tags={ration.tags}
                duration={ration.durationDays}
                kcal={ration.nutrition.calorie}
                proteins={ration.nutrition.protein}
                fats={ration.nutrition.fat}
                carbohydrates={ration.nutrition.carbo}
              />
            ))}
          </InfiniteScroll>
        </div>
      </div>
      <div className={`ration__right-container ${isFilterOpen && 'open'}`}>
        <div
          className={`ration__right-top-container ${isFilterOpen && 'open'}`}
          data-testid="ration-top-container"
        >
          <RationListFilter
            onFilterClick={() => setIsFilterOpen(!isFilterOpen)}
            handleSearch={handleSearch}
          />
        </div>
      </div>
    </section>
  );
};

export default RationList;
