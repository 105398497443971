import { FormattedMessage, useIntl } from 'react-intl';
import './ProgressBar.scss';
import { PROGRESS_BAR_COLORS } from '../../common/constant/colorsConstant';

interface ProgressBarProps {
  currentWeight: number;
  targetWeight: number;
  startingWeight: number;
  weightUnit: string;
  formatWeightUnit: (unit: string) => string;
}

interface WeightDisplayConfig {
  labelId: string;
  value: number;
  unit: string;
}

export const calculateGainWeightProgress = (
  currentWeight: number,
  startingWeight: number,
  targetWeight: number,
): number => (
  (currentWeight - startingWeight) / (targetWeight - startingWeight)
) * 100;

export const calculateLostWeightProgress = (
  currentWeight: number,
  startingWeight: number,
  targetWeight: number,
): number => (
  (startingWeight - currentWeight) / (startingWeight - targetWeight)
) * 100;

export const roundToOneDecimal = (value: number): number => Math.round(value * 10) / 10;

export const getProgressBarColor = (
  hasExceededGainingTarget: boolean,
  hasExceededLosingTarget: boolean,
  PROGRESS_BAR_COLORS: { EXCEEDED: string; DEFAULT: string },
): string => (
  (hasExceededGainingTarget || hasExceededLosingTarget)
    ? PROGRESS_BAR_COLORS.EXCEEDED
    : PROGRESS_BAR_COLORS.DEFAULT
);

export const getRemainingWeight = (
  currentWeight: number,
  targetWeight: number,
  hasExceededGainingTarget: boolean,
  hasExceededLosingTarget: boolean,
  roundToOneDecimal: (value: number) => number,
): number => {
  if (hasExceededGainingTarget) {
    return roundToOneDecimal(currentWeight - targetWeight);
  }
  if (hasExceededLosingTarget) {
    return roundToOneDecimal(targetWeight - currentWeight);
  }
  return roundToOneDecimal(Math.abs(targetWeight - currentWeight));
};

const ProgressBar = ({
  currentWeight,
  targetWeight,
  startingWeight,
  weightUnit,
  formatWeightUnit,
}: ProgressBarProps) => {
  const isGainingWeight = startingWeight < targetWeight;
  const isLosingWeight = startingWeight > targetWeight;
  const actualWeightChange = currentWeight - startingWeight;
  const gainedOrLostWeight = Math.abs(actualWeightChange);
  const displayUnit = formatWeightUnit(weightUnit);
  const intl = useIntl();

  const hasExceededGainingTarget = isGainingWeight && currentWeight > targetWeight;
  const hasExceededLosingTarget = isLosingWeight && currentWeight < targetWeight;

  const weightConfig: WeightDisplayConfig[] = [
    { labelId: 'profile.weight.start', value: startingWeight, unit: displayUnit },
    { labelId: 'profile.weight.current', value: currentWeight, unit: displayUnit },
    { labelId: 'profile.weight.target', value: targetWeight, unit: displayUnit },
  ];

  const getGainingWeightProgress = () => {
    if (currentWeight >= targetWeight) {
      return 100;
    }
    if (currentWeight < startingWeight) {
      return 0;
    }
    return calculateGainWeightProgress(currentWeight, startingWeight, targetWeight);
  };

  const getLosingWeightProgress = () => {
    if (currentWeight <= targetWeight) {
      return 100;
    }
    if (currentWeight > startingWeight) {
      return 0;
    }
    return calculateLostWeightProgress(currentWeight, startingWeight, targetWeight);
  };

  const getProgress = () => {
    if (isGainingWeight) {
      return getGainingWeightProgress();
    }
    if (isLosingWeight) {
      return getLosingWeightProgress();
    }
    return 0;
  };

  const getRemainingWeightMessage = () => {
    if (hasExceededGainingTarget) {
      return intl.formatMessage({ id: 'profile.weight.need.lost' });
    }
    if (hasExceededLosingTarget) {
      return intl.formatMessage({ id: 'profile.weight.need.gain' });
    }
    return isGainingWeight
      ? intl.formatMessage({ id: 'profile.weight.need.gain' })
      : intl.formatMessage({ id: 'profile.weight.need.lost' });
  };

  const remainingWeight = Math.abs(targetWeight - currentWeight);

  return (
    <div className="goal-progress">
      <div className="goal-progress__container">
        <div className="goal-progress__bar">
          <div
            className="goal-progress__fill"
            style={{
              width: `${getProgress()}%`,
              maxWidth: '100%',
              background: getProgressBarColor(
                hasExceededGainingTarget,
                hasExceededLosingTarget,
                PROGRESS_BAR_COLORS,
              ),
            }}
          />
        </div>
        <div className="goal-progress__value">
          {weightConfig.map(({ labelId, value, unit }) => (
            <span key={labelId}>
              {labelId && <FormattedMessage id={labelId} />}
              <span>
                {roundToOneDecimal(value)}
                {' '}
                {unit}
              </span>
            </span>
          ))}
        </div>
      </div>
      <div className="goal-progress__info">
        <p>
          {actualWeightChange >= 0
            ? intl.formatMessage({ id: 'profile.weight.gained' })
            : intl.formatMessage({ id: 'profile.weight.lost' })}
          <span>
            {roundToOneDecimal(gainedOrLostWeight)}
            {' '}
            {displayUnit}
          </span>
        </p>
        <hr />
        <p>
          {remainingWeight > 0 ? (
            <>
              {getRemainingWeightMessage()}
              <span>
                {getRemainingWeight(
                  currentWeight,
                  targetWeight,
                  hasExceededGainingTarget,
                  hasExceededLosingTarget,
                  roundToOneDecimal,
                )}
                {' '}
                {displayUnit}
              </span>
            </>
          ) : (
            intl.formatMessage({ id: 'profile.weight.goal.reached' })
          )}
        </p>
      </div>
    </div>
  );
};

export default ProgressBar;
