import React from 'react';
import './PageNotFound.scss';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const PageNotFound = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="page-not-found">
      <h1>
        <span>40</span>
        <span className='drop'>4</span>
        <span className="shadow">4</span>
      </h1>
      <h2>
        <FormattedMessage id='error.page.not.found' />
      </h2>
      <p>
        <FormattedMessage id='error.page.not.found.message' />
      </p>
      <button onClick={goBack} className="apply animated-gradient">
        <FormattedMessage id='error.page.back' />
      </button>
    </div>
  );
};

export default PageNotFound;
