import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { getWeightProgress, updateWeight } from '../../redux/slice/userSlice';
import ModalWindow from '../layout/modalWindow/ModalWindow';
import { RootState } from '../../redux';
import { useAppDispatch } from '../../hook/useAppDispatch';
import './WeightUpdateDialog.scss';
import Explanation from '../../asset/icons/reminder/explanation.svg';
import Scales from '../../asset/icons/reminder/reminder-group.svg';
import ProgressBar from '../progressBar/ProgressBar';
import { Button, ButtonType } from '../form/button/Button';
import { useAppSelector } from '../../hook/useAppSelector';

interface WeightUpdateDialogProps {
  handleClose: () => void;
}

const DEFAULT_VALUE_CURRENT_WEIGHT_PLACEHOLDER = '0';
const DEFAULT_VALUE_TARGET_WEIGHT_PLACEHOLDER = '0';

const WeightUpdateDialog = ({ handleClose }:WeightUpdateDialogProps) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const {
    currentWeight, weightGoal, firstWeight, weightUnit,
  } = useAppSelector((state: RootState) => state.user.weightProgress);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isCurrentExplanationVisible, setIsCurrentExplanationVisible] = useState(false);
  const [isTargetExplanationVisible, setIsTargetExplanationVisible] = useState(false);
  const [inputCurrentWeight, setInputCurrentWeight] = useState(currentWeight);
  const [inputTargetWeight, setInputTargetWeight] = useState(weightGoal);

  useEffect(() => {
    dispatch(getWeightProgress());
  }, [dispatch]);

  useEffect(() => {
    setInputCurrentWeight(currentWeight);
    setInputTargetWeight(weightGoal);
  }, [currentWeight, weightGoal]);

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isModalOpen]);

  const handleCurrentWeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputCurrentWeight(Number(e.target.value));
  };

  const handleTargetWeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputTargetWeight(Number(e.target.value));
  };

  const handleWeightUpdate = async (e: React.FormEvent) => {
    e.preventDefault();

    const weightData = {
      weight: inputCurrentWeight,
      weightGoal: inputTargetWeight,
      weightUnit,
    };

    await dispatch(updateWeight(weightData));
    handleClose();
  };

  const formatWeightUnit = (unit: string) => {
    let formattedUnit = '';

    if (unit === 'KILOGRAM') {
      formattedUnit = intl.formatMessage({ id: 'form.option.kilogram' });
    } else if (unit === 'POUND') {
      formattedUnit = intl.formatMessage({ id: 'form.option.pound' });
    }
    return formattedUnit;
  };

  const handleCurrentWeightClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsCurrentExplanationVisible(!isCurrentExplanationVisible);
  };

  const handleTargetWeightClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsTargetExplanationVisible(!isTargetExplanationVisible);
  };

  const renderContent = () => (
    <div className="reminder">
      <h2 className="reminder__title">
        <FormattedMessage id="weight.update.title" />
      </h2>
      <img className="reminder__picture" src={Scales} alt="Scales" />
      <ProgressBar
        currentWeight={currentWeight}
        targetWeight={weightGoal}
        startingWeight={firstWeight}
        weightUnit={weightUnit}
        formatWeightUnit={formatWeightUnit}
      />
      <form onSubmit={handleWeightUpdate}>
        <div className="input-group">
          <label htmlFor="current-weight">
            <FormattedMessage id="form.label.currentWeight" />
          </label>
          <div className="input-container">
            <input
              type="number"
              id="current-weight"
              value={inputCurrentWeight === 0 ? '' : inputCurrentWeight}
              onChange={handleCurrentWeightChange}
              placeholder={DEFAULT_VALUE_CURRENT_WEIGHT_PLACEHOLDER}
              name="current-weight"
            />
            <div className="input-group__weight-unit" id="weight-unit">
              {formatWeightUnit(weightUnit)}
            </div>
            <button
              className="input-container__unit-descr"
              onClick={handleCurrentWeightClick}
            >
              <img src={Explanation} alt="Description of the unit" />
              <div className={`input-container__unit-txt ${isCurrentExplanationVisible ? 'visible' : ''}`}>
                <FormattedMessage id="weight.update.explanation" />
              </div>
            </button>
          </div>
        </div>
        <div className="input-group">
          <label htmlFor="target-weight">
            <FormattedMessage id="form.label.targetWeight" />
          </label>
          <div className="input-container">
            <input
              type="number"
              id="target-weight"
              value={inputTargetWeight === 0 ? '' : inputTargetWeight}
              onChange={handleTargetWeightChange}
              name="target-weight"
              placeholder={DEFAULT_VALUE_TARGET_WEIGHT_PLACEHOLDER}
            />
            <div className="input-group__weight-unit" id="weight-unit">
              {formatWeightUnit(weightUnit)}
            </div>
            <button
              className="input-container__unit-descr"
              onClick={handleTargetWeightClick}
            >
              <img src={Explanation} alt="Description of the unit" />
              <div className={`input-container__unit-txt ${isTargetExplanationVisible ? 'visible' : ''}`}>
                <FormattedMessage id="weight.update.explanation" />
              </div>
            </button>
          </div>
        </div>
        <Button type={ButtonType.SUBMIT} label="weight.button.update" />
      </form>
    </div>
  );

  return (
    <div>
      {isModalOpen && (
        <ModalWindow
          customClassName="weight-update-modal"
          handleCloseModal={() => {
            setIsModalOpen(false);
            handleClose();
          }}
        >
          {renderContent()}
        </ModalWindow>
      )}
    </div>
  );
};

export default WeightUpdateDialog;
