export const VALIDATION_REGEX = {
  ONE_NUMBER: /^(?=.*\d)/,
  NO_SPECIAL_CHARACTER: /^[\w\s]*$/,
  ONLY_LETTER_EN_UA: /[a-zA-Zа-яА-ЯёЁіІїЇєЄґҐ]+/,
  ONLY_LETTERS_UA: /[а-яА-ЯёЁіІїЇєЄґҐ]/,
  ONLY_LETTERS_EN: /[A-Za-z]/,
  ONLY_LETTERS_EN_OR_NUMBER: /^[a-zA-Z0-9. ]+$/,
  NO_SPACE_INSIDE_TEXT: /^[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*\.?$/,
  ONLY_ENGLISH_LETTERS_AND_ALLOWED_SYMBOLS: /^[A-Za-z0-9!@#$%^&*()+=._-]+$/,
  NO_SPACES_ALLOWED: /^\S*$/,
  NO_DOTS_ALLOWED: /^[^.]*$/,
  LEAST_ONE_UPPERCASE_EN: /[A-Z]/,
  LEAST_ONE_LOWERCASE_EN: /[a-z]/,
};
