import './ApplyRationModal.scss';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import AddRationCalendar from './calendar/AddRationCalendar';
import { getOccupiedDaysInMyRation, resetOccupiedDays } from '../../../redux/slice/myRationSlice';
import rationService from '../../../service/ration/rationService';
import { setMessage } from '../../../redux/slice/messageSlice';
import { convertToErrorMessage } from '../../../service/message/messageConverter';
import { useAppDispatch } from '../../../hook/useAppDispatch';
import { useAppSelector } from '../../../hook/useAppSelector';
import { APP_PATH } from '../../../common/constant/pathConstant';
import { DATE_PICKER_FORMAT } from '../../../common/constant/dateFormat';

interface ApplyRationModalProps {
    rationId: string;
    durationDays: number;
    setIsModalOpen: (isOpen: boolean) => void;
}

const ApplyRationModal = ({ setIsModalOpen, rationId, durationDays }: ApplyRationModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDatesStrings, setSelectedDatesStrings] = useState<string[]>([]);
  const [repeatCount, setRepeatCount] = useState<number>(1);
  const [selectedMonth, setSelectedMonth] = useState<number>(new Date().getMonth() + 1);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const occupiedDates = useAppSelector((state) => state.myRation.occupiedDays);
  const currentOccupiedDatesForMonth = occupiedDates.find((month) => month.month === selectedMonth);
  const resetTime = (date: Date) => new Date(date.getFullYear(), date.getMonth(), date.getDate());
  const currentDate = resetTime(new Date());
  const targetDate = resetTime(new Date(selectedDatesStrings[0]));
  const isCurrentDateLessThanTarget = targetDate < currentDate;

  useEffect(() => {
    if (!currentOccupiedDatesForMonth) {
      dispatch(getOccupiedDaysInMyRation({ month: selectedMonth, year: currentYear }));
    }
  }, [selectedMonth, currentOccupiedDatesForMonth, dispatch, currentYear]);

  const handleApplyRation = async () => {
    setIsLoading(true);
    let collectedDataToPost;
    if (selectedDatesStrings[0] && repeatCount && rationId !== undefined) {
      collectedDataToPost = { start: selectedDatesStrings[0], repeatCount, rationId };
    }
    if (rationId && collectedDataToPost) {
      rationService.applyRation(rationId, collectedDataToPost)
        .then(() => {
          const formattedTargetDate = targetDate ? format(targetDate, DATE_PICKER_FORMAT) : '';
          navigate(`${APP_PATH.DASHBOARD}/${formattedTargetDate}`);
        })
        .catch((error: any) => {
          dispatch(setMessage(convertToErrorMessage(error)));
        })
        .finally(() => {
          setIsLoading(false);
          setIsModalOpen(false);
          dispatch(resetOccupiedDays());
        });
    }
  };

  return (
    <div className="modal-window" id="modal-open">
      <div className="add-ration-window">
        <div className="add-ration-window__title">
          <h1><FormattedMessage id="ration.modal.title" /></h1>
          <p><FormattedMessage id="ration.modal.subtitle" /></p>
        </div>
        <div className="add-ration-window__content">
          <AddRationCalendar
            occupiedDates={currentOccupiedDatesForMonth?.content || ['']}
            setSelectedMonth={setSelectedMonth}
            durationDay={durationDays}
            setSelectedDatesStrings={setSelectedDatesStrings}
            repeatCount={repeatCount}
            setRepeatCount={setRepeatCount}
            isCurrentDateLessThanTarget={isCurrentDateLessThanTarget}
          />
        </div>
        <div className="add-ration-window__buttons">
          <button className="cancel" onClick={() => setIsModalOpen(false)}>
            <FormattedMessage id="cancel.button" />
          </button>
          <button
            data-testid="apply"
            className="apply"
            disabled={selectedDatesStrings.length === 0 || isCurrentDateLessThanTarget}
            onClick={handleApplyRation}
          >
            {isLoading ? (
              <span data-testid="apply-spinner" className="spinner-border spinner-border-sm" />
            ) : (
              <FormattedMessage id="apply.button" />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ApplyRationModal;
