import {
  initialAppliedSearchParamObject, initialSelectedSearchObject,
  RationFilterApiResponse,
  RationSearchSelectedParam,
} from '../../../redux/slice/rationSlice';
import {
  RationSearchRequestDto,
  RationSearchRequestDtoOptional,
} from '../../../common/constant/interface/interfaces';

const mapToRationSearchRequestDto = (
  filterApiResponse: RationFilterApiResponse,
): RationSearchRequestDto => ({
  ...initialAppliedSearchParamObject,
  calorieMin: filterApiResponse.calorieMin,
  calorieMax: filterApiResponse.calorieMax,
  proteinMin: filterApiResponse.proteinMin,
  proteinMax: filterApiResponse.proteinMax,
  carboMin: filterApiResponse.carboMin,
  carboMax: filterApiResponse.carboMax,
  fatMin: filterApiResponse.fatsMin,
  fatMax: filterApiResponse.fatsMax,
  durationDaysMin: filterApiResponse.durationDaysMin,
  durationDaysMax: filterApiResponse.durationDaysMax,
});

const mapSelectedSearchObjToRationSearchRequestDto = (
  selectedSearchParam: RationSearchSelectedParam,
  availableFilters: RationFilterApiResponse,
): RationSearchRequestDtoOptional => {
  const request: RationSearchRequestDtoOptional = {};

  const fieldMapping: { [key: string]: keyof RationFilterApiResponse } = {
    calorieMin: 'calorieMin',
    calorieMax: 'calorieMax',
    proteinMin: 'proteinMin',
    proteinMax: 'proteinMax',
    carboMin: 'carboMin',
    carboMax: 'carboMax',
    fatMin: 'fatsMin',
    fatMax: 'fatsMax',
    durationDaysMin: 'durationDaysMin',
    durationDaysMax: 'durationDaysMax',
  };

  Object.keys(fieldMapping).forEach((field) => {
    const filterField = fieldMapping[field];
    // @ts-ignore
    if (selectedSearchParam[field] !== availableFilters[filterField]) {
      // @ts-ignore
      request[field] = selectedSearchParam[field];
    }
  });

  request.tagIds = selectedSearchParam.tags.map((el) => el.id);
  request.ingredientIds = selectedSearchParam.ingredients.map((el) => el.id);
  request.mealIds = selectedSearchParam.meals.map((el) => el.id);
  request.name = selectedSearchParam.name;

  return request;
};

const mapFilterApiResponseToRationSearchSelectedParam = (
  filterApiResponse: RationFilterApiResponse,
  selectedSearchParam: RationSearchSelectedParam = initialSelectedSearchObject,
): RationSearchSelectedParam => ({
  calorieMin: filterApiResponse.calorieMin,
  calorieMax: filterApiResponse.calorieMax,
  proteinMin: filterApiResponse.proteinMin,
  proteinMax: filterApiResponse.proteinMax,
  carboMin: filterApiResponse.carboMin,
  carboMax: filterApiResponse.carboMax,
  fatMin: filterApiResponse.fatsMin,
  fatMax: filterApiResponse.fatsMax,
  durationDaysMin: filterApiResponse.durationDaysMin,
  durationDaysMax: filterApiResponse.durationDaysMax,
  name: selectedSearchParam.name,
  tags: selectedSearchParam.tags,
  meals: selectedSearchParam.meals,
  ingredients: selectedSearchParam.ingredients,
});

const mapToRationSearchSelectedParam = (
  appliedFilters: RationSearchRequestDto,
): RationSearchSelectedParam => ({
  ...initialAppliedSearchParamObject,
  calorieMin: appliedFilters.calorieMin,
  calorieMax: appliedFilters.calorieMax,
  proteinMin: appliedFilters.proteinMin,
  proteinMax: appliedFilters.proteinMax,
  carboMin: appliedFilters.carboMin,
  carboMax: appliedFilters.carboMax,
  fatMin: appliedFilters.fatMin,
  fatMax: appliedFilters.fatMax,
  durationDaysMin: appliedFilters.durationDaysMin,
  durationDaysMax: appliedFilters.durationDaysMax,
  ingredients: appliedFilters.ingredients,
  meals: appliedFilters.meals,
  tags: appliedFilters.tags,
});

const rationMapper = {
  mapToRationSearchRequestDto,
  mapToRationSearchSelectedParam,
  mapFilterApiResponseToRationSearchSelectedParam,
  mapSelectedSearchObjToRationSearchRequestDto,
};

export default rationMapper;
