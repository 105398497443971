import {
  useEffect, useRef, useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import NavWeightUpdate from '../../asset/icons/navigation/nav-weight-update.svg';
import NavBurger from '../../asset/icons/navigation/nav-burger.svg';
import { ReactComponent as Pen } from '../../asset/icons/navigation/nav-nutritions-pen.svg';
import { APP_PATH } from '../../common/constant/pathConstant';
import UserAvatar from '../userAvatar/UserAvatar';
import { AVATAR_SIZES } from '../../common/constant/sizesAvatar';
import './NavBar.scss';
import { useAppDispatch } from '../../hook/useAppDispatch';
import { useAppSelector } from '../../hook/useAppSelector';
import authService from '../../service/auth/authService';
import {
  getUserProfile,
  fetchNutritionGoals, setShowUpdateWeightDialog,
} from '../../redux/slice/userSlice';
import NutritionGoal from './components/NutritionGoal';
import WeightUpdateDialog from '../weightUpdateDialog/WeightUpdateDialog';
import Title from '../title/Title';

const menuNavItem = [
  { name: 'Rations', label: 'nav.menu.rations', url: APP_PATH.APP_RATIONS },
  { name: 'Meals', label: 'nav.menu.meals', url: APP_PATH.APP_MEALS },
  { name: 'Ingredients', label: 'nav.menu.ingredients', url: APP_PATH.APP_INGREDIENTS },
  { name: 'Calculator', label: 'nav.menu.calculator', url: APP_PATH.APP_CALCULATE },
];

const NavBar = () => {
  const dispatch = useAppDispatch();
  const [isNutritionGoalOpen, setIsNutritionGoalOpen] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState<string>('');
  const [isNavigationMenuOpen, setIsNavigationMenuOpen] = useState<boolean>(false);
  const location = useLocation();
  const { userId } = authService.getCurrentUser();
  const { imageUrl, name, appliedGoals } = useAppSelector((state) => state.user);
  const menuRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);
  const isUpdateWeightDialogOpen = useAppSelector((state) => state.user
    .app.management.showUpdateWeighDialog);

  useEffect(() => {
    dispatch(getUserProfile(userId));
    dispatch(fetchNutritionGoals());
  }, [dispatch, userId]);

  useEffect(() => {
    const currentPath = location.pathname;
    const activePage = menuNavItem.find((page) => page.url === currentPath);
    if (activePage) {
      setActiveMenuItem(activePage.name);
    } else {
      setActiveMenuItem('');
    }
  }, [location]);

  const openMenu = () => {
    setIsNavigationMenuOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const closeMenu = () => {
    setIsNavigationMenuOpen(false);
    document.body.style.overflow = 'auto';
  };

  const handleClickOutside = (event: MouseEvent | TouchEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      closeMenu();
    }
  };

  useEffect(() => {
    if (isUpdateWeightDialogOpen) {
      overlayRef.current?.addEventListener('click', handleClickOutside);
    } else {
      overlayRef.current?.removeEventListener('click', handleClickOutside);
    }

    return () => {
      overlayRef.current?.removeEventListener('click', handleClickOutside);
    };
  }, [isUpdateWeightDialogOpen]);

  const toggleNutritionGoalOpen = () => {
    setIsNutritionGoalOpen((prevIsOpen) => !prevIsOpen);
  };

  return (
    <>
      <section className="navigation" data-testid="navbar">
        <div className="navigation__logo">
          <Title />
        </div>
        <nav ref={menuRef} className={`navigation__menu menu-nav ${isNavigationMenuOpen ? 'open' : ''}`}>
          <div className="menu-nav__mobile">
            <ul className="menu-nav__list">
              {menuNavItem.map(({ name, url, label }) => (
                <li className="menu-nav__item" key={name}>
                  <Link
                    className={`menu-nav__link ${activeMenuItem === name ? 'active' : ''}`}
                    to={url}
                    onClick={() => closeMenu()}
                  >
                    <FormattedMessage id={label} />
                  </Link>
                </li>
              ))}
            </ul>
            <button
              className={`burger-menu burger-menu--close ${isNavigationMenuOpen ? 'open' : ''}`}
              onClick={closeMenu}
            />
          </div>
        </nav>
        <div ref={overlayRef} className={`menu-overlay ${isNavigationMenuOpen ? 'open' : ''}`} />
        <div className="navigation__r-wrap">
          <div className="navigation__actions actions-nav">
            <div className="actions-nav__calorage nav-calorage">
              <h2 className="nav-calorage__descr">
                <FormattedMessage id="nav.menu.yourCalorage" />
              </h2>
              <button className="nav-calorage__data" onClick={toggleNutritionGoalOpen}>
                <div>
                  {appliedGoals.calorieGoal}
                  {' '}
                  <FormattedMessage id="meal.item.kcal" />
                </div>
                <Pen />
              </button>
              {isNutritionGoalOpen && (
                <NutritionGoal handleClose={() => setIsNutritionGoalOpen(false)} />
              )}
            </div>
            <button
              type="button"
              className="actions-nav__weight-update"
              onClick={() => dispatch(setShowUpdateWeightDialog(true))}
            >
              <img src={NavWeightUpdate} alt="Weight Update" />
            </button>
          </div>
          <div className="navigation__profile profile-nav">
            <h2 className="profile-nav__name">{name}</h2>
            <Link to={APP_PATH.PROFILE}>
              <button type="button" className="profile-nav__button">
                <UserAvatar srcImage={imageUrl} name={name} size={AVATAR_SIZES.MEDIUM} />
              </button>
            </Link>
          </div>
          <button className="burger-menu" onClick={openMenu}>
            <img src={NavBurger} alt="Menu" />
          </button>
        </div>
      </section>
      {isUpdateWeightDialogOpen && (
        <WeightUpdateDialog handleClose={() => dispatch(setShowUpdateWeightDialog(false))} />
      )}
    </>
  );
};

export default NavBar;
